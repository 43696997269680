export const NAMESPACE_ORDER = 'order';

//
// ──────────────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R   L I S T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────
//

export const FETCH_ORDERS = 'fetchOrders';
export const SAVE_LIST_ORDERS = 'saveListOrders';
export const FILTER_ORDERS = 'filterOrder';
export const UPDATE_ORDER_IN_LIST = 'updateOrderInList';

//
// ──────────────────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R   D E T A I L : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────
//

export const UPDATE_ORDER_STATUS = 'updateOrderStatus';
export const CANCEL_ORDER = 'cancelOrder';
export const SAVE_ORDER_DETAIL = 'saveOrderDetail';
export const GET_ORDER_BY_ID = 'getOrderById';
export const FETCH_ORDER_TEMPLATES = 'fetchOrderTemplates';
export const SET_ORDER_TEMPLATES = 'setOrderTemplates';
export const FETCH_ORDER_SUGGESTIONS = 'fetchOrderSuggestions';
export const SET_ORDER_SUGGESTIONS = 'setOrderSuggestions';
export const CREATE_ORDER = 'createOrder';
export const FETCH_INSTANT_PRICE = 'fetchInstantPrice';

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: S H I P M E N T S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const FETCH_SHIPMENTS = 'fetchShipments';
export const SAVE_SHIPMENTS = 'saveShipments';
export const FETCH_SHIPMENT_DETAIL = 'fetchShipmentDetail';
export const UPDATE_SHIPMENT_IN_LIST = 'updateShipmentInList';
export const SAVE_SHIPMENT_DOCUMENTS = 'saveShipmentDocuments';
export const SAVE_LOCATIONS = 'saveLocations';
export const SAVE_SHIPMENT_BIDDINGS = 'saveShipmentBidding';
export const RESET_SHIPMENT_BIDDINGS = 'resetShipmentBidding';
export const CLEAR_PROPS_IN_SHIPMENTS = 'clearPropsInShipments';

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: B I D D I N G S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const FETCH_BIDDINGS = 'fetchBiddings';
export const SELECT_BIDDING = 'selectBidding';
export const DESELECT_BIDDING = 'deselectBidding';
export const ACCEPT_BIDDING = 'acceptBidding';
export const FETCH_SELECTED_BIDDINGS = 'fetchSelectedBiddings';
export const FETCH_BIDDINGS_SUCCESS = 'fetchBiddingsSuccess';
export const SELECT_BIDDING_SUCCESS = 'selectBiddingSuccess';
export const DESELECT_BIDDING_SUCCESS = 'deselectBiddingSuccess';
export const ACCEPT_BIDDING_SUCCESS = 'acceptBiddingSuccess';
export const FETCH_SELECTED_BIDDINGS_SUCCESS = 'fetchSelectedBiddingsSuccess';

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: T R U C K   Q U O T A T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

export const FETCH_TRUCK_QUOTATIONS = 'fetchTruckQuotations';
export const SAVE_TRUCK_QUOTATIONS = 'saveTruckQuotations';

export const CHANGE_TRUCK_QUOTATION = 'changeTruckQuotation';
export const UPDATE_CHANGED_QUOTATION = 'updateChangedQuotation';

export const GET_QUOTATION_DETAIL = 'getQuotationDetail';
export const SAVE_QUOTATION_DETAIL = 'saveQuotationDetail';

export const REMOVE_PROMOTION = 'removePromotion';
export const UPDATE_REMOVED_PROMOTION = 'updateRemovedPromotion';

export const UPDATE_ORDER_NOTES_AND_IMAGES = 'updateOrderNotesAndImages';
export const SAVE_ORDER_NOTES_AND_IMAGES = 'saveOrderNotesAndImages';

export const GET_PROMOTIONS = 'getPromotions';
export const SAVE_PROMOTIONS = 'savePromotions';

export const GET_PAYMENT_METHODS = 'getPaymentMethods';
export const SAVE_PAYMENT_METHODS = 'savePaymentMethods';

export const ACCEPT_ORDER = 'acceptOrder';
export const UPDATE_ORDER_DETAIL_AFTER_ACCEPT = 'updateOrderDetailAfterAccept';

export const GET_BANK_ACCOUNTS = 'getBankAccounts';
export const SAVE_BANK_ACCOUNTS = 'saveBankAccounts';

export const CLEAR_PROPS_IN_HYBRID_ORDER = 'clearPropsInHybridOrder';
