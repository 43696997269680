import { updateUser, uploadIdentity, argeeLoginowContract } from '@/services/profile';
import {
  queryCurrentCompany,
  createBrokerCompany,
  updateBrokerCompany,
  uploadImageCompany,
} from '@/services/company';

import isEmpty from 'lodash/isEmpty';
import { formatMessage } from 'umi/locale';
import {
  createAccount,
  getAccounts,
  deleteAccount,
  updateRoleAccount,
  activateAccount,
  deactivateAccount,
} from '@/services/account';

import * as types from '@/actionTypes/profileTypes';
import { message } from 'antd';
import Logger from '@/utils/logger';
import profileSelectors from '@/selectors/profileSelectors';
import { getUser } from '@/utils/authority';
import { routerRedux } from 'dva/router';
import * as actions from '@/actions/profileActions';
import loginActions from '@/actions/loginActions';

export default {
  namespace: types.NAMESPACE_PROFILE,

  state: {
    user: {},
    company: {},
    listAccounts: [],
    pagesListAccounts: 0,
  },

  effects: {
    *[types.FETCH_CURRENT_USER]() {
      console.log('types.FETCH_CURRENT_USER', types.FETCH_CURRENT_USER);
      yield;
    },
    *[types.ARGEE_LOGINOW_CONTRACT]({ payload }, { call, put }) {
      const { brokerId, params, callback } = payload;
      const response = yield call(argeeLoginowContract, brokerId, params);
      if (isEmpty(response)) return;
      yield put(actions.argeeLoginowContractSuccess());
      callback();
    },
    *[types.UPDATE_USER]({ payload }, { call, put }) {
      const { values, brokerId, callback } = payload;
      try {
        const response = yield call(updateUser, brokerId, values);
        if (isEmpty(response)) return;
        const { broker } = response;
        yield put(actions.saveCurrentUser({ user: broker }));
        callback();
        message.success(formatMessage({ id: 'profile.update_account_success' }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.UPLOAD_IDENTITY]({ payload }, { call, put }) {
      const { brokerId, params } = payload;
      try {
        const response = yield call(uploadIdentity, brokerId, params);
        if (response) {
          const { document } = response;
          yield put(actions.uploadIdentitySuccess(document));
          message.success(formatMessage({ id: 'form.edit-success' }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.FETCH_COMPANY]({ payload }, { call, put }) {
      try {
        const { companyId } = payload;
        if (companyId) {
          const response = yield call(queryCurrentCompany, companyId);
          if (!response) return;
          yield put(actions.saveCompany({ company: response.broker_company }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.CREATE_BROKER_COMPANY]({ payload }, { call, put }) {
      try {
        const {
          values: { isEdit, ...company },
        } = payload;
        const response = yield call(createBrokerCompany, company);
        if (response) {
          const { broker_company } = response;
          yield put(actions.saveCompany({ company: broker_company }));
          if (isEdit) {
            message.success(formatMessage({ id: 'form.edit-success' }));
            return;
          }
          message.success(formatMessage({ id: 'form.create-success' }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.UPDATE_BROKER_COMPANY]({ payload }, { call, put }) {
      const { companyId, values, isRegister } = payload;
      try {
        const response = yield call(updateBrokerCompany, companyId, values);
        const { broker_company } = response;
        // FIXME: This line missing data
        // yield put(loginActions.saveLogin({ broker }));
        yield put(actions.saveCompany({ company: broker_company }));
        message.success(formatMessage({ id: 'form.edit-success' }));
        if (isRegister) {
          const user = getUser();
          const broker = {
            ...user,
            broker_company,
          };
          yield put(loginActions.saveLogin({ broker }));
          yield put(loginActions.redirectAfterLogin({ broker }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.UPLOAD_PHOTO_COMPANY]({ payload }, { call, put, select }) {
      const { companyId, values, isRegister } = payload;
      try {
        const response = yield call(uploadImageCompany, companyId, values);
        message.success(formatMessage({ id: 'form.upload-success' }));
        if (isRegister) {
          const user = getUser();
          const newCurrentUser = {
            ...user,
            broker_company: {
              ...user.broker_company,
              documents: [response.document],
              document_uploaded: true,
            },
          };
          yield put(loginActions.saveLogin({ broker: newCurrentUser }));
          yield put(actions.saveCurrentUser({ user: newCurrentUser }));
          yield put(routerRedux.push('/user/register/step4'));
        } else {
          const currentCompany = yield select(state => state.profile.company);
          const newCurrentCompany = {
            ...currentCompany,
            documents: [response.document, ...currentCompany.documents],
          };
          yield put(actions.saveCompany({ company: newCurrentCompany }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.FETCH_ACCOUNTS]({}, { call, put, take, select }) {
      yield take(actions.saveCurrentUser({}).type);
      const companyId = yield select(state => profileSelectors.getCompanyId(state));
      const profile = yield select(state => profileSelectors.getUser(state));
      if (profile.roles.indexOf('owner') >= 0 && companyId) {
        const response = yield call(getAccounts, companyId);
        if (response) {
          yield put(actions.saveAccounts({ brokers: response.brokers, pages: response.pages }));
        }
      }
    },
    *[types.CREATE_ACCOUNT]({ payload }, { call, put }) {
      const { companyId, broker, callback } = payload;
      try {
        const response = yield call(createAccount, companyId, broker);
        if (isEmpty(response)) return;
        yield put(actions.saveAccountAfterCreate({ account: response.broker }));
        message.success(formatMessage({ id: 'form.company.add-member-success' }));
        callback();
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.DELETE_ACCOUNT]({ payload }, { call, put }) {
      const { companyId, brokerId } = payload;
      if (!companyId || !brokerId) {
        message.error(formatMessage({ id: 'form.company.not-found' }));
        return;
      }
      try {
        const response = yield call(deleteAccount, companyId, brokerId);
        if (isEmpty(response)) return;
        yield put(actions.saveAccountAfterDelete({ account_id: brokerId }));
        message.success(formatMessage({ id: 'form.company.delete-member-success' }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.UPDATE_ROLE_ACCOUNT]({ payload }, { call, put }) {
      const { params } = payload;
      try {
        const response = yield call(updateRoleAccount, params);
        if (isEmpty(response)) return;
        yield put(actions.updateAccountSuccess({ broker: response.broker }));
        message.success(formatMessage({ id: 'form.company.change-role-success' }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.ACTIVE_ACCOUNT]({ payload }, { call, put }) {
      try {
        const { brokerId, companyId } = payload;
        const response = yield call(activateAccount, brokerId, companyId);
        if (isEmpty(response)) return;
        yield put(actions.updateAccountSuccess({ broker: response.broker }));
        message.success(formatMessage({ id: 'form.company.active-success' }));
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.DEACTIVE_ACCOUNT]({ payload }, { call, put }) {
      try {
        const { brokerId, companyId } = payload;
        const response = yield call(deactivateAccount, brokerId, companyId);
        if (isEmpty(response)) return;
        yield put(actions.updateAccountSuccess({ broker: response.broker }));
        message.success(formatMessage({ id: 'form.company.deactive-success' }));
      } catch (e) {
        Logger.log(e);
      }
    },
  },

  reducers: {
    [types.SAVE_CURRENT_USER](state, { payload }) {
      return {
        ...state,
        user: payload.user,
        company: payload.user?.broker_company,
      };
    },
    [types.UPLOAD_IDENTITY_SUCCESS](state, { payload }) {
      const { user } = state;
      const { document } = payload;
      return {
        ...state,
        user: {
          ...user,
          documents: [document, ...user.documents],
        },
      };
    },
    [types.CHANGE_NOTIFY_COUNT](state, { payload }) {
      return {
        ...state,
        user: {
          ...state.user,
          notifyCount: payload.totalCount,
          unreadCount: payload.unreadCount,
        },
      };
    },
    [types.SAVE_COMPANY](state, { payload }) {
      return {
        ...state,
        company: {
          ...state.company,
          ...payload.company,
        },
      };
    },
    [types.SAVE_ACCOUNTS](state, { payload }) {
      return {
        ...state,
        listAccounts: payload.brokers,
        pagesListAccounts: payload.pages,
      };
    },
    [types.SAVE_ACCOUNT_AFTER_CREATE](state, { payload }) {
      return {
        ...state,
        listAccounts: [...state.listAccounts, payload.account],
      };
    },
    [types.SAVE_ACCOUNT_AFTER_DELETE](state, { payload }) {
      return {
        ...state,
        listAccounts: state.listAccounts.filter(l => l.id !== payload.account_id),
      };
    },
    [types.UPDATE_ACCOUNT_SUCCESS](state, { payload }) {
      const { broker } = payload;
      return {
        ...state,
        listAccounts: state.listAccounts.map(l => (l.id === broker.id ? broker : l)),
      };
    },
    [types.RESET_ACCOUNT_AFTER_LOGOUT]() {
      return {
        user: {},
        company: {},
        listAccounts: [],
        pagesListAccounts: 0,
      };
    },
    [types.ARGEE_LOGINOW_CONTRACT_SUCCESS](state) {
      return {
        ...state,
        user: {
          ...state.user,
          e_contract_agreed: true,
        },
      };
    },
  },
};
