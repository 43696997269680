export default {
  'form.get-captcha': 'Get Captcha',
  'form.add-row': 'Thêm dòng',
  'form.captcha.second': 'sec',
  'form.optional': ' (không bắt buộc) ',
  'form.skip': 'Bỏ qua',
  'form.back': 'Quay lại',
  'form.submit': 'Lưu',
  'form.save': 'Lưu',
  'form.send': 'Gửi',
  'form.edit': 'Sửa',
  'form.required': 'Bắt buộc',
  'form.cancel': 'Huỷ',
  'form.delete': 'Xoá',
  'form.create-success': 'Tạo thành công.',
  'form.edit-success': 'Chỉnh sửa thành công.',
  'form.upload-success': 'Tải giấy tờ thành công.',
  'form.email.placeholder': 'Email',
  'form.name.placeholder': 'Họ tên',
  'form.password.placeholder': 'Password',
  'form.confirm-password.placeholder': 'Confirm password',
  'form.phone-number.placeholder': 'Số điện thoại',
  'form.verification-code.placeholder': 'Verification code',
  'form.tax_number.label': 'Mã số thuế',
  'form.tax_number.placeholder': 'Nhập mã số thuế',
  'form.date.label': 'Start and end date',
  'form.date.placeholder.start': 'Start date',
  'form.date.placeholder.end': 'End date',
  'form.goal.label': 'Goal description',
  'form.goal.placeholder': 'Please enter your work goals',
  'form.standard.label': 'Metrics',
  'form.standard.placeholder': 'Please enter a metric',
  'form.client.label': 'Client',
  'form.client.label.tooltip': 'Target service object',
  'form.client.placeholder':
    'Please describe your customer service, internal customers directly @ Name / job number',
  'form.company.name.label': 'Tên doanh nghiệp',
  'form.company.name.placeholder': 'Nhập tên doanh nghiệp',
  'form.company.address.label': 'Địa chỉ',
  'form.company.address.placeholder': 'Nhập địa chỉ',
  'form.company.add-member-success': 'Thêm tài khoản thành công',
  'form.company.delete-member-success': 'Xoá tài khoản thành công',
  'form.company.not-found': 'Không tìm thấy tài khoản',
  'form.company.change-role-success': 'Thay đổi vai trò thành công',
  'form.company.active-success': 'Kích hoạt tài khoản thành công',
  'form.company.deactive-success': 'Khóa tài khoản thành công',
  'form.invites.label': 'Inviting critics',
  'form.invites.placeholder': 'Please direct @ Name / job number, you can invite up to 5 people',
  'form.weight.label': 'Weight',
  'form.weight.placeholder': 'Please enter weight',
  'form.public.label': 'Target disclosure',
  'form.public.label.help': 'Customers and invitees are shared by default',
  'form.public.radio.public': 'Public',
  'form.public.radio.partially-public': 'Partially public',
  'form.public.radio.private': 'Private',
  'form.publicUsers.placeholder': 'Open to',
  'form.publicUsers.option.A': 'Colleague A',
  'form.publicUsers.option.B': 'Colleague B',
  'form.publicUsers.option.C': 'Colleague C',
  // order
  'form.order.validate-location': 'Bắt buộc phải chọn kết quả xổ xuống.',
  'form.order.product-code': 'Mã sản phẩm',
  'form.order.description': 'Mô tả',
  'form.order.validate-do': 'Tạo mã DO trước khi tạo các item',
  'form.order.no-code': 'Chưa có mã',
  'form.order.enter-code': 'Nhập mã',
  'form.order.select-do': 'Chọn Delivery Order',
  'form.order.clear-do': 'Xoá Delivery Order đã chọn',
  'form.order.select-do-title': 'Chọn delivery order cần dỡ xuống tại mỗi điểm',
  'form.order.add-do': 'Thêm Deliver Order',
  'form.order.add-dropoff-location': 'Thêm điểm dỡ hàng',
  'form.order.add-pickup-location': 'Thêm điểm bốc hàng',
  'form.order.additional-info': 'Thông tin thêm',
  'form.order.offer-price': 'Giá mong muốn',
  'form.order.create-new-order': 'Tạo đơn hàng mới',
  'form.order.error-not-found-company': 'Không tìm thấy thông tin công ty.',
  'form.order.validate-unit': 'Vui lòng nhập đơn vị.',
  'form.order.add-packaging-spec': 'Thêm quy chuẩn',
  'form.order.add-new-packaging-spec': 'Thêm quy chuẩn hàng mới',
  'form.order.size': 'Kích thước',
  'form.order.weight-per-unit': 'Trọng lượng (kg) mỗi đơn vị',
  'form.order.has-do': 'Có Delivery Order',
  'form.order.no-do': 'Không có Delivery Order',
  'form.order.accepted-bidding-success': 'Chấp nhận báo giá thành công!!!',
  'form.order.need-truck-type': 'Cần chọn loại xe',
  'form.order.need-truck-model': 'Cần chọn trọng tải xe',
  'form.order.notes': 'Ghi chú',
  'form.order.estimated_cost': 'Giá ước tính',
  'form.order.estimated_cost.unit': 'Đơn vị',
  'form.order.estimated_cost.number': 'Số lượng đơn vị ước tính',
  'form.order.estimated_cost.selling': 'Đơn giá ước tính',
  'form.order.created_at': 'Ngày đăng',
  'form.quickorder.name': 'Vui lòng nhập họ tên',
  'form.quickorder.phone': 'Vui lòng nhập đúng định dạng số điện thoại',
  'form.quickorder.size': 'Số phải lớn hơn 0',
  'form.quickorder.truckmodel': 'Vui lòng chọn loại thùng',
  'form.choose_province_city': 'Chọn tỉnh/thành phố',
  'form.choose_district': 'Chọn quận/huyện',
};
