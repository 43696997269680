export const order_suggestions = {
  truck_type_id: [1, 6],
  truck_model_id: [7, 9, 10, 45, 52],
  truck_quantity: [1, 3, 10],
  DO: [
    {
      wordId: '12345',
      name: 'FHGK23231 Cá Hồi',
      department: 'San Siaro, Nono',
    },
    {
      wordId: '54321',
      name: 'KFADW0923 Mía',
      department: 'Ahabama, Ladia',
    },
  ],
};

export const fakeData = {};
