module.exports = {
  navTheme: 'dark', // theme for nav menu
  primaryColor: '#ec6726', // primary color of ant design
  layout: 'sidebar', // nav menu position: sidemenu or topmenu
  contentWidth: 'Fluid', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  autoHideHeader: false, // auto hide header
  fixSiderbar: false, // sticky siderbar
  title: 'Tra Giá Thuê Xe Tải Chở Hàng Nam Bắc Liên Tỉnh | LOGIVAN',
};
