import { get, put, post } from '@/utils/request';

export const queryCurrentCompany = companyId =>
  get({
    endpoint: `/broker_companies/${companyId}`,
  });

export const updateBrokerCompany = (companyId, values) =>
  put({
    shouldRedirect: false,
    endpoint: `/broker_companies/${companyId}`,
    params: {
      broker_company: values,
    },
  });

export const uploadImageCompany = (companyId, values) =>
  post({
    endpoint: `/broker_companies/${companyId}/documents`,
    params: {
      document: values,
    },
  });

export const createBrokerCompany = company =>
  post({
    shouldRedirect: false,
    customUrl: true,
    endpoint: '/broker/broker_companies',
    params: {
      broker_company: company,
    },
  });

export const getTaxnumberInfo = taxNumber =>
  get({
    shouldRedirect: false,
    endpoint: `/company_api_info/${taxNumber}`,
  });

export const autocompleteCargoType = (brokerCompanyId, params) =>
  get({
    shouldRedirect: false,
    customUrl: true,
    endpoint: `/broker/broker_companies/${brokerCompanyId}/broker_company_cargo_types/autocomplete`,
    params,
  });
