/**
 * Logivan
 */

export const LOGIVAN_PHONE = '033131618';
export const LOGIVAN_SOURCE = 'https://logivan.com';
export const LOGIVAN_ACCOUNT_NUMBER = '020077155555';

/**
 * Constants
 */

export const DATETIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm A';
export const INPUT_DELAY = 300;
export const TIME_SHOW_NOTIFICATION = 2;
export const LENGTH_OTP = 4;
export const LIMIT_IMAGE = 3;
export const MAX_SIZE_UPLOAD = {
  IMAGE: 5242880, // 5MB
};
export const TRUCK_TYPE_DEFAULT = 5;

/**
 * Services
 */

export const GOOGLE_MAP_SERVICES_STATUS = Object.freeze({
  OK: 'OK',
  ZERO_RESULTS: 'ZERO_RESULTS',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
});

export const WEB_URLS = {
  PRIVACY: 'https://www.logivan.com/chinh-sach-bao-mat/',
  POLICY: 'https://www.logivan.com/dieu-khoan-su-dung/',
};
