/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */ // needed for hasura _lge and _gte etc
import axios from 'axios';
import config from '@/utils/config';
import _ from 'lodash';
import { gql } from '@apollo/client';

const getStatusFilter = mdsType => {
  /*
  shipment enum status: {
    created: 'created',
    started: 'started',
    arrived_pickup: 'arrived_pickup',
    picked_up: 'picked_up',
    arrived_dropoff: 'arrived_dropoff',
    dropped_off: 'dropped_off',
    cancelled: 'cancelled'
  }
  */
  if (mdsType === 'completed') {
    return { status: { _eq: 'dropped_off' } };
  }
  if (mdsType === 'ongoing') {
    return { status: { _in: ['started', 'arrived_pickup', 'picked_up', 'arrived_dropoff'] } };
  }
  if (mdsType === 'upcoming') {
    return { status: { _eq: 'created' } };
  }
  return { status: { _neq: 'cancelled' } };
};

export const getDateDelivereisFilters = (end_date, start_date) => {
  if (!end_date && !start_date) {
    return {};
  }
  return {
    pickup_date: {
      ...(start_date ? { _gte: start_date.split(' ')[0] } : {}),
      ...(end_date ? { _lte: end_date.split(' ')[0] } : {}),
    },
  };
};
export const getDateOrderFilters = (end_date, start_date) => {
  if (!end_date && !start_date) {
    return {};
  }
  const dateFilter = { pickup_datetime: {} };
  if (end_date) {
    const endDate = new Date(`${end_date}Z`);
    endDate.setHours(endDate.getHours() - 7);
    dateFilter.pickup_datetime._lte = endDate.toISOString().split('.')[0];
  }
  if (start_date) {
    const startDate = new Date(`${start_date}Z`);
    startDate.setHours(startDate.getHours() - 7);
    dateFilter.pickup_datetime._gte = startDate.toISOString().split('.')[0];
  }
  return dateFilter;
};

export const getWhere = ({
  type,
  end_date,
  start_date,
  dropoff_province_ids,
  pickup_province_ids,
  keyword,
  keyword_type,
}) => {
  const user = JSON.parse(localStorage.getItem('development'));
  const brokerCompanyId = user?.broker_company_id;
  const shipmentStatusFilter = getStatusFilter(type);
  return {
    ...shipmentStatusFilter,
    ...(keyword && keyword_type === 'shipment_id' ? { id: { _eq: _.toNumber(keyword) } } : {}),
    ...(keyword && keyword_type === 'driver_name'
      ? { driver: { name: { _eq: `${keyword}` } } }
      : {}),
    ...(keyword && keyword_type === 'plate' ? { truck: { plate: { _eq: `${keyword}` } } } : {}),
    order: {
      ...(keyword && keyword_type === 'order_id' ? { id: { _eq: _.toNumber(keyword) } } : {}),
      status: { _nin: [0, 2, 3] },
      ...(dropoff_province_ids?.length
        ? { dropoff_locations: { area_id: { _in: dropoff_province_ids } } }
        : {}),
      ...(pickup_province_ids?.length
        ? { pickup_locations: { area_id: { _in: pickup_province_ids } } }
        : {}),
      _or: [
        {
          deliveries: {
            ...getDateDelivereisFilters(end_date, start_date),
            ...(keyword && keyword_type === 'po'
              ? { customer_data: { _contains: { customer_id: keyword } } }
              : {}),
            broker_company_id: {
              _eq: brokerCompanyId,
            },
          },
        },
        {
          ...getDateOrderFilters(end_date, start_date),
          ...(keyword && keyword_type === 'po'
            ? { deliveries: { customer_data: { _contains: { customer_id: keyword } } } }
            : {}),
          order_broker: {
            broker_company_id: {
              _eq: brokerCompanyId,
            },
          },
        },
      ],
    },
  };
};

export const SHIPMENT_COUNTS_QUERY = `query counts(
  $whereCompleted: shipments_bool_exp
  $whereUpcoming: shipments_bool_exp
  $whereOngoing: shipments_bool_exp
) {
  completed: shipments_aggregate(where: $whereCompleted) {
    aggregate {
      count
    }
  }
  upcoming: shipments_aggregate(where: $whereUpcoming) {
    aggregate {
      count
    }
  }
  ongoing: shipments_aggregate(where: $whereOngoing) {
    aggregate {
      count
    }
  }
}`;

export const LOCATION_FIELDS = `{
  id
  location_type
  description
  contact_name
  contact_phone_number
  city
  note
  lat
  lng
  area_id
  area {
    name
    id
  }
}`;

export const DELIVERY_FIELDS = `{
  broker_company_id
  pickup_date,
  pickup_time,
  dropoff_date,
  dropoff_time,
  cargo_types,
  weight_expected,
  cargo_unit_expected_width,
  cargo_unit_expected_height,
  cargo_unit_expected_length,
  notes,
  customer_data
  price_billing
  id
  pickup_location {
    id
    location_type
    description
    name
    contact_name
    contact_phone_number
    city
    note
    lat
    lng
    area_id
    area {
      name
      id
    }
  }
  dropoff_location {
    id
    location_type
    description
    name
    contact_name
    contact_phone_number
    city
    note
    lat
    lng
    area_id
    area {
      name
      id
    }
  }
}`;

export const SHIPMENTS_QUERY = brokerCompanyId => `
  query dashboardShipments($where: shipments_bool_exp, $limit: Int!, $offset: Int!) {
    shipments_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    shipments(
      where: $where, order_by: [{id: desc}]
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      status
      steps(order_by: [{id: asc}]){
        id
        status
        type
        delivery_id
        delivery {
          pickup_location {
            id
          }
          dropoff_location {
            id
          }
        }
      }
      truck {
        plate
        truck_model {
          box_dimensions
          truck_type {
            name
          }
        }
      }
      created_at
      driver {
        id 
        name
        avatar_url
        phone_number
      }
      order {
        id
        status
        preferences
        plan
        deliveries(
          order_by: [ { id: desc } ],
          where: { broker_company_id: { _eq: ${brokerCompanyId} } }
        ) ${DELIVERY_FIELDS}
        truck_requests {
          truck_model {
            box_dimensions
            truck_type {
              name
            }
          }
        }
        truck_model {
          box_dimensions
          truck_type {
            name
          }
        }
        cargo_weight
        cargo_types
        pickup_datetime
        dropoff_datetime
        pickup_locations(order_by: [{id: desc}]) ${LOCATION_FIELDS}
        dropoff_locations(order_by: [{id: desc}]) ${LOCATION_FIELDS}
      }
    }
  }
`;

export const AREAS = gql`
  query areas($location_type: Int!, $broker_company_id: Int!, $broker_company_id_bigint: bigint!)
    @cached(ttl: 300) {
    areas: unaccented_areas(
      where: {
        locations: {
          location_type: { _eq: $location_type }
          _or: [
            { delivery_pickup: { broker_company_id: { _eq: $broker_company_id } } }
            { delivery_pickup: { broker_company_id: { _eq: $broker_company_id } } }
            { order: { order_broker: { broker_company_id: { _eq: $broker_company_id_bigint } } } }
          ]
        }
      }
      order_by: [{ name_unaccented: asc }]
    ) {
      id
      name
      name_unaccented
    }
  }
`;

const getFacilities = async params => {
  const user = JSON.parse(localStorage.getItem('development'));
  const broker_company_id = user?.broker_company_id;
  const apikey = user?.mds_key;
  try {
    const rs = await axios.post(
      `${config.getKcaServerPath()}/kca_insight/facilities?apikey=${apikey}`,
      {
        ...params,
        customer_id: broker_company_id,
      }
    );
    return rs?.data?.data;
  } catch (error) {
    return error.response;
  }
};

const incidentalCostByMonth = async params => {
  const user = JSON.parse(localStorage.getItem('development'));
  const broker_company_id = user?.broker_company_id;
  const apikey = user?.mds_key;
  try {
    const rs = await axios.post(
      `${config.getKcaServerPath()}/kca_insight/incidental_cost_by_month?apikey=${apikey}`,
      {
        ...params,
        customer_id: broker_company_id,
      }
    );
    return rs?.data?.data;
  } catch (error) {
    return error.response;
  }
};

const shipmentCountAndWeight = async params => {
  const user = JSON.parse(localStorage.getItem('development'));
  const broker_company_id = user?.broker_company_id;
  const apikey = user?.mds_key;
  try {
    const rs = await axios.post(
      `${config.getKcaServerPath()}/kca_insight/shipment_count_and_weight?apikey=${apikey}`,
      {
        ...params,
        customer_id: broker_company_id,
      }
    );
    return rs?.data?.data;
  } catch (error) {
    return error.response;
  }
};

const incidentalCost = async params => {
  const user = JSON.parse(localStorage.getItem('development'));
  const broker_company_id = user?.broker_company_id;
  const apikey = user?.mds_key;
  try {
    const rs = await axios.post(
      `${config.getKcaServerPath()}/kca_insight/incidental_cost?apikey=${apikey}`,
      {
        ...params,
        customer_id: broker_company_id,
      }
    );
    return rs?.data?.data;
  } catch (error) {
    return error.response;
  }
};

export { getFacilities, incidentalCostByMonth, shipmentCountAndWeight, incidentalCost };
