import { get, post, remove, put, patch } from '@/utils/request';

export const getAccounts = (companyId, params) =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/broker_companies/${companyId}/brokers`,
    params,
  });

export const createAccount = (companyId, values) =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/broker_companies/${companyId}/brokers`,
    params: {
      broker: values,
    },
  });

export const updateRoleAccount = ({ brokerId, companyId, roleName }) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/broker_companies/${companyId}/brokers/${brokerId}/role`,
    params: { role_name: roleName },
  });

export const activateAccount = (brokerId, companyId) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/broker_companies/${companyId}/brokers/${brokerId}/activate `,
  });

export const deactivateAccount = (brokerId, companyId) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/broker_companies/${companyId}/brokers/${brokerId}/deactivate `,
  });

export const deleteAccount = (companyId, brokerId) =>
  remove({
    shouldRedirect: false,
    customUrl: true,
    endpoint: `/broker/broker_companies/${companyId}/remove_broker/${brokerId}`,
  });

export const sendEmailAuthenticate = email =>
  post({
    endpoint: '/broker/brokers/password',
    customUrl: true,
    params: {
      broker: { email },
    },
  });

export const resetPassword = params =>
  put({
    endpoint: '/broker/brokers/password',
    customUrl: true,
    params: {
      broker: params,
    },
  });

export const setNewpassword = params =>
  put({
    endpoint: '/broker/brokers/invitation',
    customUrl: true,
    params: {
      accept_invitation: params,
    },
  });
