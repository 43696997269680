export const SHIPMENT_STATUS = Object.freeze({
  CREATED: 'created',
  ARRIVED_PICKUP: 'arrived_pickup',
  PICKED_UP: 'picked_up',
  ARRIVED_DROPOFF: 'arrived_dropoff',
  STARTED_AT: 'started',
  DROPPED_OFF: 'dropped_off',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
});

export const ORDER_STATUS = Object.freeze({
  POSTED: 'posted',
  EXPIRED: 'expired',
  HAS_BIDDING: 'has_bidding',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  PAID: 'paid',
  BIDDING_QUOTED: 'bidding_quoted',
  BIDDING_ACCEPTED: 'bidding_accepted',
  SHIPMENT_NEW: 'shipment_new',
  SHIPMENT_IN_TRANSIT: 'shipment_in_transit',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
});

export const BROKER_STATUS = Object.freeze({
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
});

export const COMPANY_STATUS = Object.freeze({
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
});

export const TIME_CLOSE = 5;

export default {
  SHIPMENT_STATUS,
  ORDER_STATUS,
  BROKER_STATUS,
  TIME_CLOSE,
};
