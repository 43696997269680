export default {
  'shipment.created': 'Driver accepted',
  'shipment.arrived_pickup': 'Arrived pickup location',
  'shipment.picked_up': 'Pickup completed',
  'shipment.started': 'Started',
  'shipment.arrived_dropoff': 'Arrived dropoff location',
  'shipment.dropped_off': 'Drop off completed',
  'shipment.completed': 'Completed',
  'shipment.cancelled': 'Cancelled',
  'shipment.follow-trip': 'Follow trip',
  'shipment.running': 'Running',
  'shipment.history': 'Shipment history',
  'shipment.transport_truck_info': 'Transport truck information',
  'shipment.delivery_record': 'Delivery Record',
  'shipment.driver-detail': 'Driver Detail',
};
