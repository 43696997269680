import configs from '@/constants/urlConstants';
import { getAccessToken, getUser } from './authority';

const isDevelopedEnviroment = () => APP_ENV !== 'production';
console.log('isDevelopedEnviroment', isDevelopedEnviroment());
console.log('APP_ENV', APP_ENV);
console.log('APP_ID', APP_ID);
console.info('isDevelopedEnviroment', isDevelopedEnviroment());
console.info('APP_ENV', APP_ENV);

const LIST_SERVER = [
  'https://logivan.herokuapp.com',
  'https://logivan-testbed1.herokuapp.com',
  'https://logivan-staging-pr-xxx.herokuapp.com',
];

const LIST_KCA = [3498, 6368, 1355, 4536, 6723, 2913, 1313, 8019, 7715, 8055];

const saveServerPathToStorage = serverPath => {
  if (localStorage.getItem('serverPath')) localStorage.removeItem('serverPath');
  localStorage.setItem('serverPath', serverPath);
  window.location.reload();
};

const getServerPath = () => {
  if (localStorage && localStorage.getItem('serverPath')) return localStorage.getItem('serverPath');
  return configs[APP_ENV].apiEndpoint;
};

const getDefaultServerPath = () => configs[APP_ENV].apiEndpoint;

const getKcaServerPath = () => {
  if (localStorage && localStorage.getItem('serverPath')) {
    if (localStorage.getItem('serverPath') === 'https://logivan.herokuapp.com') {
      return 'https://api.logivan.com/mds/api';
    }
    if (localStorage.getItem('serverPath') === 'https://logivan-testbed1.herokuapp.com') {
      return 'https://apitest.logivan.com/mds/api';
    }
    return 'https://apitest.logivan.com/mds/api';
  }
  return 'https://api.logivan.com/mds/api';
};

const isUseDevelopedServer = () => getServerPath() !== configs.production.apiEndpoint;

export const getAutoImportBookingLink = () => {
  const userId = getUser()?.id;
  const token = getAccessToken();
  if (!userId || !token) return '';
  const origin = isUseDevelopedServer() ? 'https://abtest.logivan.com/' : 'https://ab.logivan.com/';
  const link = `${origin}#/import?userId=${userId}&token=${token}`;
  return link;
};

export default {
  api: configs[APP_ENV].apiEndpoint,
  version: configs.version,
  isDevelopedEnviroment,
  LIST_SERVER,
  LIST_KCA,
  saveServerPathToStorage,
  getServerPath,
  getDefaultServerPath,
  isUseDevelopedServer,
  getAutoImportBookingLink,
  getKcaServerPath,
};
