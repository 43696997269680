import dva from 'dva';
import createLoading from 'dva-loading';

const runtimeDva = window.g_plugins.mergeConfig('dva');
let app = dva({
  history: window.g_history,
  
  ...(runtimeDva.config || {}),
});

window.g_app = app;
app.use(createLoading());
(runtimeDva.plugins || []).forEach(plugin => {
  app.use(plugin);
});

app.model({ namespace: 'global', ...(require('/home/runner/work/logivan-shippers-webapp/logivan-shippers-webapp/src/models/global.js').default) });
app.model({ namespace: 'login', ...(require('/home/runner/work/logivan-shippers-webapp/logivan-shippers-webapp/src/models/login.js').default) });
app.model({ namespace: 'menu', ...(require('/home/runner/work/logivan-shippers-webapp/logivan-shippers-webapp/src/models/menu.js').default) });
app.model({ namespace: 'order', ...(require('/home/runner/work/logivan-shippers-webapp/logivan-shippers-webapp/src/models/order.js').default) });
app.model({ namespace: 'profile', ...(require('/home/runner/work/logivan-shippers-webapp/logivan-shippers-webapp/src/models/profile.js').default) });
app.model({ namespace: 'setting', ...(require('/home/runner/work/logivan-shippers-webapp/logivan-shippers-webapp/src/models/setting.js').default) });
