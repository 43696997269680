import * as types from '@/actionTypes/loginTypes';

const namespace = types.NAMESPACE_LOGIN;

export const login = payload => ({
  type: `${namespace}/${types.LOGIN}`,
  payload,
});

export const fetchAuthenticationToken = ({ hasuraResponse, callback }) => ({
  type: `${namespace}/${types.FETCH_AUTHENTICATION_TOKEN}`,
  payload: { hasuraResponse, callback },
});

export const getOTPEsmsSuccess = remainTime => ({
  type: types.GET_OTP_ESMS_SUCCESS,
  payload: {
    remainTime,
  },
});

export const handleUpdateUserRegister = payload => ({
  type: `${namespace}/${types.HANDLE_UPDATE_USER_REGISTER}`,
  payload,
});

export const logout = payload => ({
  type: `${namespace}/${types.LOGOUT}`,
  payload,
});

export const saveLogin = ({ broker, data }) => ({
  type: `${namespace}/saveLogin`,
  payload: { broker, data },
});

export const redirectAfterLogin = ({ broker, callback }) => ({
  type: types.REDIRECT_AFTER_LOGIN,
  payload: {
    broker,
    callback,
  },
});

export default {
  login,
  fetchAuthenticationToken,
  getOTPEsmsSuccess,
  handleUpdateUserRegister,
  logout,
  saveLogin,
  redirectAfterLogin,
};
