/* eslint-disable-next-line import/no-extraneous-dependencies */
import axios from 'axios';
import { getHasuraAccessToken } from '@/utils/authority';
import { get, patch, post } from '@/utils/request';
import { DELIVERY_FIELDS, LOCATION_FIELDS } from './dashboard';

//
// ──────────────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R   L I S T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────
//

export const getListOrder = params =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: '/broker/orders',
    params,
  });

//
// ──────────────────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R   D E T A I L : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────
//
export const ORDER_STATUSES = {
  0: 'draft',
  1: 'posted',
  2: 'expired',
  3: 'cancelled',
  4: 'completed',
  5: 'has_bidding',
  6: 'bidding_quoted',
  7: 'bidding_accepted',
  8: 'shipment_new',
  9: 'shipment_in_transit',
  10: 'paid',
};

export const ORDER_STATUS_MAP = {
  draft: 0,
  posted: 1,
  expired: 2,
  cancelled: 3,
  completed: 4,
  has_bidding: 5,
  bidding_quoted: 6,
  bidding_accepted: 7,
  paid: 10,
  shipment_new: 8,
  shipment_in_transit: 9,
};

export const hasuraGetOrderById = async id => {
  const payload = {
    query: `query OrderDetailApp($id: bigint!) {
      orders_by_pk(id: $id) {
        id
        preferences
        plan
        order_broker {
          broker_company_id
        }
        pickup_datetime
        dropoff_datetime
        pickup_locations(order_by: [{id: desc}]) ${LOCATION_FIELDS}
        dropoff_locations(order_by: [{id: desc}]) ${LOCATION_FIELDS}
        shipments(where: {status: {_nin: ["cancelled"]}}) {
          id
          created_at
          updated_at
          order_id
          device_id
          status
          truck {
            truck_model {
              box_dimensions
              truck_type {
                name
              }
            }
          }
          driver {
            id 
            name
            avatar_url
            phone_number
          }
          driver_id
          steps(order_by: [{id: asc}]){
            created_at
            updated_at
            id
            type
            status
            delivery ${DELIVERY_FIELDS}
          }
          documents(order_by: [{id: asc}]) {
            created_at
            data
            deleted_at
            document_type
            documentable_id
            documentable_type
            execute_result
            executed_at
            id
            images
            ng_reason_id
            note
            rejected_at
            status
            targetable_id
            targetable_type
            updated_at
            verified_at
          }
        }
        pickup_locations(order_by: [{id: desc}]) ${LOCATION_FIELDS}
        dropoff_locations(order_by: [{id: desc}]) ${LOCATION_FIELDS}
        deliveries ${DELIVERY_FIELDS}
        order_type
        status
        truck_requests {
          truck_model {
            box_dimensions
            truck_type {
              name
            }
          }
        }
        truck_model {
          box_dimensions
          truck_type {
            name
          }
        }
      }
    }`,
    variables: { id },
  };
  const meta = { headers: { Authorization: `Bearer ${getHasuraAccessToken()}` } };
  try {
    const rs = await axios.post(`https://selected-teal-89.hasura.app/v1/graphql`, payload, meta);
    const order = rs.data.data.orders_by_pk;
    return order;
  } catch (error) {
    console.error('Hasura count');
    console.error(error);
    return error.response;
  }
};

export const fetchReasonsCancelOrder = () =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: '/broker/orders/cancelled_reasons',
  });

export const cancelledReasons = (orderId, reasonId) =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/cancelled_reasons`,
    params: {
      cancelled_reason_id: reasonId,
    },
  });

export const cancelOrder = orderId =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/cancel`,
  });

export const updateOrderStatus = (orderId, status) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/action/${status}`,
  });

export const fetchOrderTemplates = brokerCompanyId =>
  get({
    customUrl: true,
    endpoint: `/broker/broker_companies/${brokerCompanyId}/order_templates`,
  });

export const createOrder = order =>
  post({
    customUrl: true,
    endpoint: '/broker/orders',
    params: {
      order,
    },
  });

export const getPackagingSpecs = brokerCompanyId =>
  get({
    customUrl: true,
    endpoint: `/broker/api/v1/broker_companies/${brokerCompanyId}/packaging_specs`,
  });

export const createPackagingSpec = (brokerCompanyId, packagingSpec) =>
  post({
    customUrl: true,
    endpoint: `/broker/api/v1/broker_companies/${brokerCompanyId}/packaging_specs`,
    params: {
      packaging_spec: packagingSpec,
    },
  });

export const exportFilteredOrder = params =>
  post({
    customUrl: true,
    endpoint: '/broker/orders/export',
    params,
  });

export const exportProcessing = key =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/export_progress?key=${key}`,
  });

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: S H I P M E N T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const getShipments = orderId =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/shipments`,
  });

export const getShipmentDetail = ({ orderId, shipmentId, params }) =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/shipments/${shipmentId}`,
    params,
  });
export const hasuraGetShipmentById = async id => {
  const payload = {
    query: `query hasuraGetShipmentById($id: bigint!) {
      shipments_by_pk(id: $id) {
        id
        created_at
        driver {
          id
          name
          avatar_url
          phone_number
        }
        status
        steps(order_by: [{id: asc}]){
          created_at
          updated_at
          id
          type
          status
          delivery ${DELIVERY_FIELDS}
        }
        truck {
          truck_type {
            name
          }
        }
      }
    }
    `,
    variables: { id },
  };
  const meta = { headers: { Authorization: `Bearer ${getHasuraAccessToken()}` } };
  try {
    const rs = await axios.post(`https://selected-teal-89.hasura.app/v1/graphql`, payload, meta);
    const order = rs.data.data.orders_by_pk;
    return order;
  } catch (error) {
    console.error('Hasura count');
    console.error(error);
    return error.response;
  }
};

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: B I D D I N G S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const fetchBiddings = (orderId, { page, limit, amount, isFilterSelected }) =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings`,
    params: {
      page,
      limit,
      filter_amount: amount,
      filter_selected: isFilterSelected,
    },
  });

export const selectBidding = (orderId, biddingId) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings/${biddingId}/select`,
  });

export const deselectBidding = (orderId, biddingId) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings/${biddingId}/deselect`,
  });

export const acceptBidding = orderId =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/biddings/accept`,
  });

export const getDriverById = driverId =>
  get({
    endpoint: `/drivers/${driverId}`,
  });

export const fetchInstantPrice = values =>
  post({
    customUrl: true,
    endpoint: '/broker/orders/list_price_details',
    params: {
      order: values,
    },
  });

export const getSuggestTruckModels = params =>
  get({
    customUrl: true,
    endpoint: '/broker/quick_orders/truck_suggest',
    params,
  });

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: T R U C K   Q U O T A T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

export const getTruckQuotations = orderId =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/service_quotations`,
  });

export const changeTruckQuotation = ({ orderId, quoId }) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/service_quotations/${quoId}/select`,
  });

export const getQuotationDetail = ({ orderId, quoId, promoId }) => {
  if (promoId) {
    return get({
      customUrl: true,
      endpoint: `/broker/orders/${orderId}/service_quotations/${quoId}?promotion_id=${promoId}`,
    });
  }

  return get({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/service_quotations/${quoId}`,
  });
};

export const removePromotion = ({ orderId, quoId }) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/service_quotations/${quoId}/remove_promotion`,
  });

export const updateOrderNotesAndImages = ({ orderId, notes, images }) =>
  patch({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}`,
    params: {
      order: {
        notes,
        images,
      },
    },
  });

export const getPromotions = code =>
  get({
    customUrl: true,
    endpoint: '/broker/promotions',
    params: {
      code,
    },
  });

export const changePromotion = ({ orderId, quoId, promoId }) =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/${orderId}/service_quotations/${quoId}?promotion_id=${promoId}`,
  });

export const getPaymentMethods = ({ orderId, fromAreaId, totalPrice }) =>
  get({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/payment_methods?from_area_id=${fromAreaId}&total_price=${totalPrice}`,
  });

export const acceptOrder = ({ orderId, quoId, params }) =>
  post({
    customUrl: true,
    endpoint: `/broker/orders/${orderId}/service_quotations/${quoId}/accept`,
    params,
  });

export const getBankAccounts = () =>
  get({
    customUrl: true,
    shouldRedirect: false,
    endpoint: '/broker/bank_accounts',
  });

export const checkBookable = ({ order }) =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/orders/bookable`,
    params: {
      order,
    },
  });

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: S H A R E - T R I P : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

export const getShareTripInfo = (code, params) =>
  get({
    params,
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/shipments/sharing/${code}`,
  });
