export default {
  'shipment.created': 'Nhận chuyến',
  'shipment.arrived_pickup': 'Đến điểm bốc hàng',
  'shipment.picked_up': 'Đã bốc hàng',
  'shipment.started': 'Bắt đầu chuyến',
  'shipment.arrived_dropoff': 'Đến điểm dỡ hàng',
  'shipment.dropped_off': 'Giao hàng hoàn tất',
  'shipment.completed': 'Hoàn thành',
  'shipment.cancelled': 'Đã hủy',
  'shipment.follow-trip': 'Xem hành trình',
  'shipment.running': 'Đang thực hiện',
  'shipment.history': 'Lịch sử hành trình',
  'shipment.transport_truck_info': 'Thông tin xe vận chuyển',
  'shipment.delivery_record': 'Biên bản giao nhận hàng',
  'shipment.driver-detail': 'Chi tiết tài xế',
};
