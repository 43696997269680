export default {
  'login.enter_email': 'Enter email',
  'login.password': 'password',
  'login.message-invalid-credentials': 'Invalid email or password',
  'login.message-invalid-verification-code': 'Invalid verification code',
  'login.tab-login-email': 'Email',
  'login.tab-login-mobile': 'Mobile number',
  'login.remember-me': 'Remember me',
  'login.forgot-password': 'Forgot your password?',
  'login.forgot-password-description':
    "Please enter your registered email and we'll send a new password reset for you.",
  'login.accept-invite-set-password': 'Please enter a new password for your account.',
  'login.enter-email': 'Enter email',
  'login.sign-in-with': 'Sign in with',
  'login.signup': 'Sign up',
  'login.login': 'Login',
  'login.set-password': 'Set login password',
  'login.set-new-password': 'Set new password',
  'login.input-password': 'Password',
  'login.confirm-password': 'Confirm password',
  'login.create-new-password': 'Create new password',
  'login.password_not_match': 'Password not match',
  'login.verification_code': 'Verification code',
  'login.enter_the_verification_code': 'Enter the verification code sent the number {phone}',
  'login.resend_verification_code': 'Resend verification code',
  'login.request_resend_verification_code': 'Request a new verification code {time}',
  'login.description_resend_verification_code':
    'We will send a confirmation code via SMS to the phone number you requested',
  'login.hi_shipper': 'Hi! Shipper',
  'login.you_need_to_log_in_to_get_quotation': 'You need to log in to get quotation',
  'login.already_have_an_account_enter_the_registered_phone_number':
    'Already have an account? Enter the registered phone number',
  'login.we_have_sent_a_verification_code_via_your_phone':
    'We have sent a verification code via the phone number you requested',
};
