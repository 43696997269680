function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import Modal from './Modal';
import confirm from './confirm';

Modal.info = function (props) {
  var config = _extends({
    type: 'info',
    iconType: 'info-circle',
    okCancel: false
  }, props);

  return confirm(config);
};

Modal.success = function (props) {
  var config = _extends({
    type: 'success',
    iconType: 'check-circle',
    okCancel: false
  }, props);

  return confirm(config);
};

Modal.error = function (props) {
  var config = _extends({
    type: 'error',
    iconType: 'close-circle',
    okCancel: false
  }, props);

  return confirm(config);
};

Modal.warning = Modal.warn = function (props) {
  var config = _extends({
    type: 'warning',
    iconType: 'exclamation-circle',
    okCancel: false
  }, props);

  return confirm(config);
};

Modal.confirm = function (props) {
  var config = _extends({
    type: 'confirm',
    okCancel: true
  }, props);

  return confirm(config);
};

export default Modal;