export default {
  'validation.email.required': 'Vui lòng nhập email',
  'validation.email.wrong-format': 'Email không hợp lệ',
  'validation.password.required': 'Vui lòng nhập mật khẩu',
  'validation.password.strength.medium': 'Strength: medium',
  'validation.password.strength.msg':
    "Please enter at least 6 characters and don't use passwords that are easy to guess.",
  'validation.password.strength.short': 'Strength: too short',
  'validation.password.strength.strong': 'Strength: strong',
  'validation.password.twice': 'Mật khẩu không đúng',
  'validation.phone-number.required': 'Thiếu số điện thoại',
  'validation.phone-number.wrong-format': 'Số điện thoại không chính xác',
};
