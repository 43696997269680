export const TABLE_PAGE_SIZE = 25;

export const DEFAULT_PAGE_NUMBER = 1;

export const LIMIT_PAGE = 25;

export const TABLE_MIN_WIDTH = 1300;

export const PAGINATION_POSITION = {
  BOTH: 'both',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const BIDDING_TABLE_HEIGHT = 400;
