export default {
  'login.enter_email': 'Nhập địa chỉ email',
  'login.password': 'Mật khẩu',
  'login.message-invalid-credentials': 'Tài khoản không hợp lệ',
  'login.message-invalid-verification-code': 'Mã đăng nhập không hợp lệ',
  'login.tab-login-email': 'Địa chỉ email',
  'login.tab-login-mobile': 'Số điện thoại',
  'login.remember-me': 'Nhớ mật khẩu',
  'login.forgot-password': 'Bạn quên mật khẩu?',
  'login.forgot-password-description':
    'Xin vui lòng nhập email đã đăng ký và chúng tôi sẽ gửi thông tin khởi tạo lại mật khẩu mới cho bạn.',
  'login.accept-invite-set-password': 'Vui lòng nhập mật khẩu mới cho tài khoản của bạn.',
  'login.enter-email': 'Nhập địa chỉ email',
  'login.sign-in-with': 'Sign in with',
  'login.signup': 'Sign up',
  'login.login': 'Đăng nhập',
  'login.set-password': 'Thiết lập mật khẩu đăng nhập',
  'login.set-new-password': 'Thiết lập mật khẩu mới',
  'login.input-password': 'Nhập mật khẩu mới',
  'login.confirm-password': 'Xác nhận lại mật khẩu',
  'login.create-new-password': 'Tạo mật khẩu mới',
  'login.password_not_match': 'Mật khẩu không đúng',
  'login.verification_code': 'Mã xác nhận',
  'login.enter_the_verification_code': 'Nhập mã xác nhận đã gửi số {phone}',
  'login.resend_verification_code': 'Gửi lại mã xác nhận',
  'login.request_resend_verification_code': 'Yêu cầu mã xác nhận mới {time}',
  'login.description_resend_verification_code':
    'Chúng tôi sẽ gửi lại mã xác nhận qua tin nhắn SMS tới số điện thoại bạn yêu cầu',
  'login.hi_shipper': 'Xin chào! Chủ hàng',
  'login.you_need_to_log_in_to_get_quotation': 'Bạn cần đăng nhập để lấy báo giá',
  'login.already_have_an_account_enter_the_registered_phone_number':
    'Đã có tài khoản? Nhập số điện thoại đăng ký',
  'login.we_have_sent_a_verification_code_via_your_phone':
    'Chúng tôi đã gửi mã xác nhận qua số điện thoại bạn yêu cầu',
};
