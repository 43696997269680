export default {
  'order.order': 'Mã đơn hàng',
  'order.trip': 'Chuyến',
  'order.pickup-location': 'Điểm bốc',
  'order.dropoff-location': 'Điểm dỡ',
  'order.information': 'Thông tin đơn hàng',
  'order.order-detail': 'Chi tiết đơn hàng',
  'order.order-weight': 'Khối lượng hàng(tấn)',
  'order.created-date': 'Ngày tạo',
  'order.pickup_datetime': 'Thời gian bốc hàng',
  'order.pickup_date': 'Ngày bốc hàng',
  'order.pickup_time': 'Giờ bốc hàng',
  'order.pickup-datetime': 'Ngày giờ bốc hàng',
  'order.dropoff-datetime': 'Ngày giờ dỡ hàng',
  'order.creator': 'Người tạo',
  'order.order-list': 'Danh sách đơn hàng',
  'order.cargo-type': 'Thông tin hàng hóa',
  'order.pickup_info': 'Thông tin điểm bốc',
  'order.dropoff_info': 'Thông tin điểm dỡ',
  'order.truck_type_id': 'Loại xe',
  'order.truck_model_id': 'Trọng tải xe',
  'order.choose-truck-body': 'Chọn loại thùng xe',
  'order.price-for-truck-quantity': 'Giá cho {quantity} xe',
  'order.bidding_status': 'Trạng thái',
  'order.cargo_types': 'Loại hàng',
  'order.title.cargo_weight': 'Khối lượng hàng',
  'order.title.cargo_size': 'Kích thước hàng',
  'order.cargo_weight': '{weight} tấn',
  'order.text.truck_quantity': 'Số lượng xe',
  'order.truck_quantity': '{quantity} xe',
  'order.order-location': 'Địa chỉ đơn hàng',
  'order.order-information': 'Thông tin đơn',
  'order.truck_request_info': 'Thông tin xe yêu cầu',
  'order.shipment_photo': 'Ảnh đính kèm',
  'order.price': 'Giá',
  'order.images': 'Ảnh',
  'order.newly_created': 'Mới tạo',
  'order.already_have_truck': 'Đã có xe',
  'order.cancel_order': 'Hủy đơn hàng',
  'order.search': 'Tìm kiếm',
  'order.trips_are_running': 'Các chuyến đi đang chạy',
  'order.trips_are_scheduled_to_run': 'Những chuyến đang lên lịch chạy',
  'order.trips_have_been_completed': 'Những chuyến đã hoàn thành',
  'order.most_recent_trips': 'Tất cả các chuyến đi gần đây nhất',
  'order.accomplished': 'Đã hoàn thành',
  'order.scheduling': 'Đang xếp lịch',
  'order.pickup_address': 'Điểm bốc hàng',
  'order.pickup_province': 'Tỉnh điểm bốc',
  'order.dropoff_province': 'Tỉnh dỡ hàng',
  'order.dropoff_address': 'Điểm dỡ hàng',
  'order.example_address': 'Ví dụ: Cát Lái',
  'order.order_id': 'Mã đơn',
  'order.shipment_id': 'Mã chuyến',
  'order.created_order_broker': 'Người tạo đơn',
  'order.example_broker': 'Ví dụ: Nguyen Van A',
  'order.address_is_incorrect': 'Địa chỉ không hợp lệ',
  'order.create_new_order': 'Tạo đơn hàng mới',
  'order.select_your_registered_trips_from_the_list': 'Chọn tuyến đường đã đăng kí từ danh sách',
  'order.add_a_new_trip': 'Thêm tuyến đường mới',
  'order.your_trip_name': 'Tuyến đường {{title}}',
  'order.order_with_new_trip': 'Đơn hàng tuyến đường mới',
  'order.shipment-detail': 'Thông tin đơn hàng',
  'order.preferences': 'Tùy chọn',
  'order.is_insurance_requested': 'Cần mua bảo hiểm',
  'order.hide_contact': 'Ẩn liên hệ',
  'order.is_tax_invoice_requested': 'Cần xuất hóa đơn điện tử',
  'order.is_whole_truck': 'Cần nguyên xe',
  'order.is_share_truck': 'Cần ghép xe',
  'order.additional_info': 'Thông tin thêm',
  'order.business_photo': 'Ảnh chứng từ',
  'order.trip-detail': 'Thông tin tuyến đường',
  'order.pickup-location-do': 'Chi tiết DO điểm bốc',
  'order.dropoff-location-do': 'Chi tiết DO điểm dỡ',
  'order.pickup': 'hàng bốc',
  'order.dropoff': 'hàng dỡ',
  'order.delivery-order-code': 'Mã Delivery Order',
  'order.code-product-description': 'Mã / Mô tả sản phẩm',
  'order.total-unit': 'Tổng đơn vị',
  'order.total-weight': 'Tổng trọng lượng',
  'order.unit': 'Số đơn vị',
  'order.weight': 'Trọng lượng',
  'order.accepted': 'Đã chấp nhận',
  'order.pending': 'Chờ châp nhận',
  'order.show-bidding': 'Xem báo giá',
  'order.list-biddings': 'Danh sách báo giá đơn hàng',
  'order.order-bidding': 'Báo giá đơn hàng',
  'order.accept-bidding': 'Chấp nhận báo giá',
  'order.reject-bidding': 'Từ chối',
  'order.bidding-created-at': 'Ngày báo giá',
  'order.billing': 'Chi phí',
  'order.total-billing': 'Tổng kết chí phí',
  'order.total-billing-of-year': 'Tổng chi phí năm',
  'order.total-billing-of-month': 'Tổng chi phí tháng',
  'order.cost-type': 'Loại chi phí',
  'order.fee-name': 'Hạng mục chi',
  'order.quantity': 'Số lượng',
  'order.total-price': 'Thành tiền',
  'order.tax-rate': 'VAT',
  'order.total-price-with-tax': 'Tổng giá',
  'order.assigned-person': 'Người phụ trách',
  'order.posted': 'Mới tạo',
  'order.expired': 'Hết hạn',
  'order.cancelled': 'Đã hủy',
  'order.completed': 'Hoàn thành',
  'order.upcoming': 'Đang xếp lịch',
  'order.isunloading': 'Đang dỡ hàng',
  'order.unloadingpoint': 'Đang đến điểm dỡ',
  'order.loadinggoods': 'Đang bốc hàng',
  'order.pickuppoint': 'Đang đến điểm bốc',
  'order.copy': 'Click để copy',
  'order.nodriver': 'Chưa có thông tin',
  'order.accept_order': 'Tài xế đã nhận chuyến',
  'order.has_bidding': 'Có báo giá',
  'order.bidding_quoted': 'Có báo giá',
  'order.bidding_accepted': 'Đã chốt giá',
  'order.shipment_new': 'Đã chốt tài xế',
  'order.shipment_in_transit': 'Đang chạy',
  'order.stt': 'STT',
  'order.route': 'Lộ trình',
  'order.shipment-documents': 'Thông tin hình ảnh hàng hoá và chứng từ',
  'order.quotation-transport': 'Báo giá vận chuyển',
  'order.order-confirm': 'Xác nhận đơn hàng',
  'order.whole_truck': 'Nguyên xe',
  'order.shared_truck': 'Ghép xe',
  'order.does_not_include_vat': 'Chưa bao gồm VAT',
  'order.enter_cargo_dimensions_to_get_shared_truck_price': 'Nhập kích thước hàng để có giá ghép',
  'order.LGV_have_not_supported_for_this_routes_and_cargo':
    'LOGIVAN chưa có cước vận chuyển cho tuyến đường và loại hàng này',
  'order.please_press': 'Vui lòng bấm',
  'order.to_view_order_and_LGV_will': 'để xem lại đơn hàng và LOGIVAN sẽ',
  'order.direct_bidding': 'báo giá trực tiếp',
  'order.for_you': 'cho bạn',
  'order.create_order_success': 'CHÚC MỪNG BẠN ĐÃ ĐẶT XE THÀNH CÔNG',
  'order.text_logivan_contact_for_you': 'LOGIVAN sẽ liên lạc ngay với bạn để chốt xe!',
  'order.text_back_home': 'Quay về trang danh sách đơn hàng trong',
  'order.sum': 'Tổng',
  'order.pretax_price': 'Giá trước thuế',
  'order.sub_total_price': 'Cước vận chuyển',
  'order.preview.pickup_location': 'Điểm đi',
  'order.preview.dropoff_location': 'Điểm đến',
  'order.preview.pickup_date': 'Ngày bốc hàng',
  'order.preview.truck_type': 'Loại xe',
  'order.preview.cargo_type': 'Loại hàng',
  'order.preview.notes': 'Ghi chú',
  'order.message.choice_shared_or_whole': 'Vui lòng chọn giá nguyên xe hoặc giá ghép hàng',
  'order.text.no_suitable_price': 'Chưa có giá phù hợp cho loại xe này',
  'order.text.no_shared_price_for_many_truck':
    'LGV chưa thể  hỗ  trợ ghép hàng với số lượng trên 1 xe',
  'order.text.with_this_payload_you_can_only_ride_the_vehicle':
    'Với trọng tải này bạn chỉ có thể đi nguyên xe',
  'order.title.bidding': 'Báo giá cho đơn hàng',
  'order.btn.ready_for_transport': 'Sẵn sàng vận chuyển',
  'order.btn.select_bidding': 'Chọn báo giá',
  'order.btn.unselect_bidding': 'Bỏ chọn',
  'order.btn.detail': 'Chi tiết',
  'order.btn.close': 'Đóng',
  'order.btn.cancel': 'Hủy bỏ',
  'order.btn.confirm': 'Đồng ý',
  'order.account-number': 'Số tài khoản',
  'order.account-name': 'Account name',
  'order.brand': 'Chi nhánh',
  'order.title.bidding_detail': 'Chi tiết báo giá',
  'order.title.shipment_info': 'Thông tin chủ xe',
  'order.title.transport_capacity': 'Năng lực vận tải',
  'order.title.sorry': 'Rất tiếc',
  'order.title.confirm_quote': 'Xác nhận báo giá!',
  'order.title.filter': 'Bộ lọc',
  'order.desc.suggested_price': 'Giá đề nghị',
  'order.desc.quote_price': 'Đã báo giá cho',
  'order.desc.date_bidding': 'Ngày báo giá',
  'order.desc.bidded_truck_for_order': 'Xe đã báo giá vận chuyển cho đơn hàng',
  'order.desc.error_not_enough_cars':
    'Số lượng xe vận chuyển vẫn chưa đủ để thực hiện việc chấp nhận đơn hàng. Bạn cần tìm đủ số  lượng xe để tiếp tục.',
  'order.desc.notification_enough_cars':
    'Số lượng xe vận chuyển từ báo giá đã đủ theo yêu cầu. Bạn có chắc chắn chấp nhận báo giá cho đơn hàng này.',
  'order.desc.not_bidding_for_order': 'Đơn hàng chưa có báo giá',
  'order.desc.truck-quantity': 'Số lượng',
  'order.desc.temporary-price': 'Giá tạm tính',
  'order.desc.warning-total-bid':
    'Bạn không thể chọn báo giá này vì số lượng xe vận chuyển sẽ vượt tổng số lượng xe yêu cầu.',
  'order.car': 'xe',
  'order.bidding_status.selected': 'Đã chọn',
  'order.bidding_status.pending': 'Đang chờ',
  'order.bidding_status.rejected': 'Từ chối',
  'order.bidding_status.cancelled': 'Đã hủy',
  'order.today': 'Hôm nay',
  'order.dayago': '{day} ngày trước',
  'order.quotations': 'Danh sách báo giá vận chuyển',
  'order.notes': 'Ghi chú cho đơn hàng',
  'order.confirm': 'Xác nhận đặt hàng',
  'order.choose-payment-method': 'Chọn thanh toán',
  'order.payment-method': 'Phương thức thanh toán',
  'order.payment-method-unsupported': 'Phương thức này chỉ áp dụng cho đơn hàng trên 1 triệu đồng',
  'order.not-choose-payment-method-yet': 'Bạn chưa chọn phương thức thanh toán.',
  'order.not-able-to-use-promotion':
    'Bạn không thể sử dụng ưu đãi cho đơn đặt hàng này. Vui lòng xem chi tiết điều khoản và điều kiện sử dụng của khuyến mãi.',
  'order.pay-with': 'Thanh toán với',
  'order.payment-info': 'Thông tin chuyển khoản',
  'order.payment-content': 'Nội dung chuyển khoản',
  'order.waiting-for-payment': 'Chờ thanh toán',
  'order.paid': 'Đã thanh toán',
  'order.cash-on-delivery': 'Thanh toán khi giao hàng(Tiền mặt)',
  'order.payment-for-order': 'Thanh toán đơn hàng #{orderId}',
  'order.bank-transfer': 'Chuyển khoản ngân hàng',
  'order.your-promotion': 'Khuyến mãi của bạn',
  'order.enter-promotion-codes': 'Nhập mã khuyến mãi',
  'order.have-no-promotion': 'Rất tiếc bạn chưa có khuyến mãi nào',
  'order.transfer-before-lgv-looking-truckers':
    'Bạn vui lòng chuyển khoản số tiền vận chuyển trước khi Logivan thực hiện tìm tài xế.',
  'order.waiting-for-confirm-from-lgv-after-payment':
    'Nếu bạn đã hoàn thành chuyển khoản xin vui lòng đợi xác nhận từ phía Logivan.',
  'order.bank-transfer-content': 'Don hang {orderId} - {user} - {phoneNumber}',
  'order.transport-services': 'Dịch vụ vận chuyển',
  'order.get-quotations': 'Lấy báo giá',
  'order.enter_full_cargo_size': 'Điền đầy đủ kích thước hàng hóa',
  'order.warning_cargo_size': 'Kích thước tối đa cho phép (D)14.3m x (R)2.4m x (C)2.7m',
  'order.input_cargo_description': 'Nhập thông tin hàng hóa',
  'order.input_address': 'Nhập địa chỉ',
  'order.lose_recipient': 'Thiếu tên người liên hệ',
  'order.pickup_and_dropoff_to_show_list':
    'Nhập điểm bốc và điểm dỡ hàng để thấy danh sách dịch vụ',
  'order.select_truck_model': 'Chọn kích thước thùng xe',
  'order.required_truck_model': 'Chọn kích thước thùng xe phù hợp',
  'order.required_truck': 'Vui lòng chọn loại xe phù hợp',
  'order.date_time_invalid': 'Vui lòng chọn ngày giờ bốc hàng hợp lệ',
  'order.what_dose_it_mean': 'Nghĩa là gì?',
  'order.information_cargo_size_description': 'Thông tin kích thước hàng để tính được giá ghép xe.',
  'order.warning_share_truck_must_have_cargo_size':
    'Loại dịch vụ ghép xe cần phải có kích thước hàng để tính được giá vận chuyển.',
  'order.warning_distance_is_invalid': 'Tuyến đường này không phù hợp với dịch vụ ghép xe',
  'order.are_you_sure_with_order_information':
    'Bạn chắc chắn lấy báo giá cho đơn hàng với khối lượng hàng là {cargoWeight} và số lượng là {truckQuantity} chứ?',
  'order.need_at_least_x_vehicles_for_every_x_tons_order':
    'Cần ít nhất là {quantity} xe cho đơn hàng {weight} tấn',
  'order.minimum-cargo-weight': 'Khối lượng hàng tối thiểu là 0.3 tấn',
  'order.required-cargo-weight': 'Nhập khối lượng hàng',
  'order.pickup_recipient': 'Người liên hệ điểm bốc',
  'order.dropoff_recipient': 'Người liên hệ điểm dỡ',
  'order.title_reason_cancel_order': 'Đơn hàng bạn rất quan trọng với tài xế',
  'order.subtitle_reason_cancel_order':
    'Vui lòng chia sẻ lý do của bạn để giúp cải thiện chất lượng dịch vụ đơn hàng',
  'order.message_cancel_success': 'Đơn hàng #{orderId} đã được hủy',
  'order.logivan_will_contact_you_as_soon_as_possible':
    'LOGIVAN sẽ liên lạc với bạn trong thời gian sớm nhất',
  'order.move_hour_later': 'Dời sang {time}',
  'order.select_other_time': 'Chọn thời gian khác',
  'order.status': 'Trạng thái đơn hàng',
  'order.away_from_dropped_off': 'Cách điểm dỡ hàng',
  'order.nearly_arrived': 'Tài xế gần đến điểm dỡ hàng',
  'order.address': 'Địa chỉ đơn hàng #{orderId}',
  'order.invalid_code': 'Liên kết bạn nhập đã hết hạn',
  'order.copy_share_link': 'Link chia sẻ đã được sao chép',
  'order.logivan_will_contact_you_to_get_billing_information':
    'Logivan sẽ liên hệ bạn để lấy thông tin xuất hóa đơn',
  'order.require_location': 'Vui lòng nhập địa chỉ',
  'order.require_detail_location':
    'Để có thể báo giá chính xác nhất, vui lòng điền địa chỉ chi tiết hơn. VD: Cảng Cát Lái, quận 2, Hồ Chí Minh',
  'order.send_econtract': 'Gửi điều khoản sử dụng về email của tôi',
  'order.truck_model': 'Trọng tải',
  'order.time_pickup': 'Thời điểm bốc',
  'order.time_dropoff': 'Thời điểm dỡ',
  'order.location_list': 'Danh sách địa điểm',
  'order.order_id_title': 'Đơn hàng #{orderId}',
  'order.order_document': 'Chứng từ của đơn hàng',
  'order.order_from_south': 'Đơn hàng miền Nam',
  'order.order_from_central': 'Đơn hàng miền Trung',
  'order.order_from_north': 'Đơn hàng miền Bắc',
  'order.do_the_goods_load_at_the_port': 'Có bốc hàng tại cảng không?',
  'order.pickup_address_placeholder': 'Cảng Cái Lân, Quảng Ninh',
  'order.dropoff_address_placeholder': 'Khu công nghiệp Phố Nối A, Hưng Yên',
  'order.cod_amount_placeholder': 'Nhập số tiền phải thu COD',
  'order.add_dropoff_address': 'Thêm điểm dỡ hàng',
  'order.delivery_order_code_to_dropoff': 'Mã hàng cần dỡ ({number} mã)',
  'order.delivery_order_code_list': 'Danh sách mã hàng',
  'order.delivery_order_total_weight': 'Tổng trọng lượng: {number} tấn',
  'order.sku_code': 'Mã SKU',
  'order.add_delivery_order_item': 'Thêm hàng',
  'order.sku_check_label': 'Có mã SKU',
  'order.cargo_type_name_label': 'Tên hàng',
  'order.delivery_orders_display_by_location': 'Từng điểm',
  'order.delivery_orders_display_by_all': 'Phiếu tổng',
  'order.description': 'Mô tả',
  'order.delivery_orders_section_summary': '{quantity} Mã hàng (Tổng trọng lượng: {weight} tấn)',
  'order.total_quantity': 'Tổng cộng',
  'order.warning_create_multi_dropoff_order':
    'Đơn hàng với nhiều điểm dỡ sẽ không thể sửa được, bạn có muốn kiểm tra lại lần nữa cho chắc chắn?',
  'order.recheck': 'Kiểm tra lại',
  'order.continue_create': 'Tiếp tục tạo',
};
