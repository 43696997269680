import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { formatMessage } from 'umi/locale';

import { doNothing } from '@/utils/utils';
import icInternalError from '@/assets/svg/ic-internal-error-107.svg';
import styles from './SysUpdateModal.less';

const modalMaskStyle = {
  opacity: 0.85,
  backgroundColor: '#ffffff',
};

export const modalAction = {
  openModal: doNothing,
  hideModal: doNothing,
};

export default class SysUpdateModal extends Component {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    modalAction.openModal = this.openModal;
    modalAction.hideModal = this.hideModal;
  }

  openModal = () => {
    this.setState({ isVisible: true });
  };

  hideModal = () => {
    this.setState({ isVisible: false }, () => window.location.reload());
  };

  render() {
    const { isVisible } = this.state;

    return (
      <Modal
        centered
        footer={null}
        closable={false}
        visible={isVisible}
        className={styles.container}
        maskStyle={modalMaskStyle}
      >
        <img src={icInternalError} alt="" />
        <div className={styles.title}>{formatMessage({ id: 'global.system_udpate' })}</div>
        <div className={styles.content}>
          {formatMessage({ id: 'global.system_information_needs_to_be_updated' })}
          <br />
          {formatMessage({ id: 'global.please_try_again' })}
        </div>
        <Button onClick={this.hideModal} type="primary" size="large">
          {formatMessage({ id: 'global.try_again' })}
        </Button>
      </Modal>
    );
  }
}
