import { routerRedux } from 'dva/router';
import { formatMessage } from 'umi/locale';
import { message } from 'antd';
import { get } from 'lodash';

import { getOTPEsms, hasuraLogin } from '@/services/login';
import { setAuthority, logout, saveCredentials } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import { updateUser } from '@/services/profile';
import Logger from '@/utils/logger';
import * as types from '@/actionTypes/loginTypes';
import * as profileActions from '@/actions/profileActions';
import actions from '@/actions/loginActions';
import { clearStorage } from '@/utils/utils';

export default {
  namespace: types.NAMESPACE_LOGIN,

  state: {},

  effects: {
    *[types.REDIRECT_AFTER_LOGIN]({ payload }, { put }) {
      const { callback } = payload;
      if (typeof callback === 'function') {
        callback();
        return;
      }
      yield put(routerRedux.push('/'));
    },
    *[types.FETCH_AUTHENTICATION_TOKEN]({ payload }, { put }) {
      try {
        const { hasuraResponse, callback } = payload;
        const { broker } = hasuraResponse;
        yield put(actions.saveLogin({ broker, data: hasuraResponse }));
        reloadAuthorized();
        callback();
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.LOGIN]({ payload }, { call, put, all }) {
      try {
        const [hasuraResponse] = yield all([call(hasuraLogin, payload)]);
        if (hasuraResponse) {
          const { broker } = hasuraResponse;
          reloadAuthorized();
          yield put(actions.saveLogin({ broker, data: hasuraResponse }));
          yield put(routerRedux.push('/'));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.GET_OTP_ESMS]({ payload }, { call, put }) {
      const { phoneNumber, callback } = payload;
      try {
        const response = yield call(getOTPEsms, phoneNumber);

        if (get(response, 'next_otp_remain_time')) {
          yield put(actions.getOTPEsmsSuccess(response.next_otp_remain_time));
          callback({
            otpRemainTime: response.next_otp_remain_time,
            needNotify: true,
          });
        } else if (get(response, '_error.code') === 'ARE-0009') {
          const otpRemainTime = get(response, '_error.message');
          yield put(actions.getOTPEsmsSuccess(otpRemainTime));
          callback({ otpRemainTime });
        } else {
          const clearOtpRemainTime = undefined;
          yield put(actions.getOTPEsmsSuccess(clearOtpRemainTime));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.HANDLE_UPDATE_USER_REGISTER]({ payload }, { call, put }) {
      const { brokerId, values } = payload;
      try {
        const response = yield call(updateUser, brokerId, values);
        if (response) {
          const { broker } = response;
          // update store
          yield put(profileActions.saveCurrentUser({ user: broker }));
          // update localstorage
          yield put(actions.saveLogin({ broker }));
          message.success(formatMessage({ id: 'form.edit-success' }));
        }
      } catch (e) {
        Logger.log(e);
      }
    },
    *[types.LOGOUT](_, { put }) {
      const roles = [];
      const user = {};
      logout();
      clearStorage();
      setAuthority(roles);
      saveCredentials(user);
      reloadAuthorized();
      yield put(profileActions.resetAccountAfterLogout());
      yield put(routerRedux.push('/user/login'));
    },
    *[types.SAVE_LOGIN]({ payload }, { put }) {
      try {
        const {
          broker,
          data,
          broker: { roles },
        } = payload;
        if (roles && roles.length === 0) broker.roles = ['user'];
        setAuthority(broker.roles);
        saveCredentials(broker, data);
        reloadAuthorized();
        yield put(profileActions.saveCurrentUser({ user: broker }));
      } catch (err) {
        Logger.log(err);
      }
    },
  },

  reducers: {
    [types.GET_OTP_ESMS_SUCCESS](state, { payload }) {
      return {
        ...state,
        otpRemainTime: payload.remainTime,
      };
    },
  },
};
