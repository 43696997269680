import profileModel from '@/models/profile';
import orderModel from '@/models/order';
import globalModel from '@/models/global';
import loginModel from '@/models/login';
import * as profileTypes from '@/actionTypes/profileTypes';
import * as orderTypes from '@/actionTypes/orderTypes';
import * as globalTypes from '@/actionTypes/globalTypes';
import * as loginTypes from '@/actionTypes/loginTypes';

export const NAMESPACE_LOADING = 'loading';

//
// ──────────────────────────────────────────────────── I ──────────
//   :::::: C O M M O N : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────
//

const getIsLoadingTaxNumber = state =>
  state[NAMESPACE_LOADING].effects[`${globalModel.namespace}/${globalTypes.FETCH_TAX_NUMBER_INFO}`];

//
// ────────────────────────────────────────────────── I ──────────
//   :::::: L O G I N : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────
//

const getIsLoadingLogin = state =>
  state[NAMESPACE_LOADING].effects[`${loginModel.namespace}/${loginTypes.LOGIN}`] ||
  state[NAMESPACE_LOADING].effects[`${loginModel.namespace}/${loginTypes.SAVE_LOGIN}`] ||
  state[NAMESPACE_LOADING].effects[`${loginModel.namespace}/${loginTypes.REDIRECT_AFTER_LOGIN}`] ||
  state[NAMESPACE_LOADING].effects[`${loginModel.namespace}/${loginTypes.GET_OTP_ESMS}`] ||
  state[NAMESPACE_LOADING].effects[
    `${loginModel.namespace}/${loginTypes.FETCH_AUTHENTICATION_TOKEN}`
  ];

const getIsLoadingGetOtpEsms = state =>
  state[NAMESPACE_LOADING].effects[`${loginModel.namespace}/${loginTypes.GET_OTP_ESMS}`];

const getIsLoadingVerifyOtpEsms = state =>
  state[NAMESPACE_LOADING].effects[`${loginModel.namespace}/${loginTypes.VERIFY_OTP_ESMS}`] ||
  state[NAMESPACE_LOADING].effects[
    `${loginModel.namespace}/${loginTypes.FETCH_AUTHENTICATION_TOKEN}`
  ];

//
// ────────────────────────────────────────────────────── I ──────────
//   :::::: P R O F I L E : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────
//

const getIsLoadingUpdateUser = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.UPDATE_USER}`];

const getIsLoadingAgreeEContract = state =>
  state[NAMESPACE_LOADING].effects[
    `${profileModel.namespace}/${profileTypes.ARGEE_LOGINOW_CONTRACT}`
  ];

const getIsFetchingCurrentUser = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.FETCH_CURRENT_USER}`];

const getIsUploadingIdentity = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.UPLOAD_IDENTITY}`];

const getIsFetchingCompany = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.FETCH_COMPANY}`];

const getIsLoadingCreateCompany = state =>
  state.loading.effects[`${profileModel.namespace}/${profileTypes.CREATE_BROKER_COMPANY}`];

const getIsLoadingUpdateCompany = state =>
  state.loading.effects[`${profileModel.namespace}/${profileTypes.UPDATE_BROKER_COMPANY}`];

const getIsFetchingAccounts = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.FETCH_ACCOUNTS}`];

const getIsDeleteAccount = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.DELETE_ACCOUNT}`];

const getIsUpdateAccount = state =>
  state[NAMESPACE_LOADING].effects[
    `${profileModel.namespace}/${profileTypes.UPDATE_ROLE_ACCOUNT}`
  ] ||
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.ACTIVE_ACCOUNT}`] ||
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.DEACTIVE_ACCOUNT}`];

const getIsCreateAccount = state =>
  state[NAMESPACE_LOADING].effects[`${profileModel.namespace}/${profileTypes.CREATE_ACCOUNT}`];

const getIsUploadingPhotoCompany = state =>
  state[NAMESPACE_LOADING].effects[
    `${profileModel.namespace}/${profileTypes.UPLOAD_PHOTO_COMPANY}`
  ];

//
// ──────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────
//

const getIsFetchOrders = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_ORDERS}`] ||
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FILTER_ORDERS}`];

const getIsGetOrderById = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.GET_ORDER_BY_ID}`];

const getIsCreateOrder = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.CREATE_ORDER}`];

const getFetchingShipments = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_SHIPMENTS}`];

const getFetchingShipmentDetail = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_SHIPMENT_DETAIL}`];

const getIsFetchAllBiddings = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_BIDDINGS}`];

const getIsFetchSelectedBiddings = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_SELECTED_BIDDINGS}`];

const getIsSelectBidding = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.SELECT_BIDDING}`];

const getIsDeselectBidding = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.DESELECT_BIDDING}`];

const getIsAcceptBidding = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.ACCEPT_BIDDING}`];

const getIsFetchingInstantPrice = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_INSTANT_PRICE}`];

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: T R U C K   Q U O T A T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

const getIsFetchingTruckQuotations = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.FETCH_TRUCK_QUOTATIONS}`];

const getChangingTruckQuotation = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.CHANGE_TRUCK_QUOTATION}`];

const getFetchingQuoDetail = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.GET_QUOTATION_DETAIL}`];

const getRemovingPromo = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.REMOVE_PROMOTION}`];

const getAcceptingOrder = state =>
  state[NAMESPACE_LOADING].effects[`${orderModel.namespace}/${orderTypes.ACCEPT_ORDER}`];

export default {
  /**
   * Global
   */

  getIsLoadingTaxNumber,

  /**
   * Login
   */

  getIsLoadingLogin,
  getIsLoadingGetOtpEsms,
  getIsLoadingVerifyOtpEsms,

  /**
   * Profile
   */

  getIsFetchingCurrentUser,
  getIsLoadingAgreeEContract,
  getIsLoadingUpdateUser,
  getIsUploadingIdentity,
  getIsFetchingCompany,
  getIsFetchingAccounts,
  getIsDeleteAccount,
  getIsCreateAccount,
  getIsUpdateAccount,
  getIsUploadingPhotoCompany,
  getIsLoadingUpdateCompany,
  getIsLoadingCreateCompany,

  /**
   * Order
   */

  getIsFetchOrders,
  getIsGetOrderById,
  getIsCreateOrder,
  getFetchingShipments,
  getFetchingShipmentDetail,
  getIsFetchAllBiddings,
  getIsAcceptBidding,
  getIsSelectBidding,
  getIsDeselectBidding,
  getIsFetchSelectedBiddings,
  getIsFetchingInstantPrice,
  getIsFetchingTruckQuotations,
  getChangingTruckQuotation,
  getFetchingQuoDetail,
  getRemovingPromo,
  getAcceptingOrder,
};
