import { patch, post } from '@/utils/request';

export const updateUser = (brokerId, values) =>
  patch({
    customUrl: true,
    endpoint: `/broker/brokers/${brokerId}`,
    shouldRedirect: true,
    params: {
      broker: values,
    },
  });

export const argeeLoginowContract = (brokerId, params) =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: `/broker/brokers/${brokerId}/agree_loginow_contract`,
    params,
  });

export const uploadIdentity = (brokerId, values) =>
  post({
    endpoint: `/brokers/${brokerId}/documents`,
    params: {
      document: values,
    },
  });
