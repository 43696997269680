import { NAMESPACE_GLOBAL } from '@/actionTypes/globalTypes';

const getTrucks = state => state[NAMESPACE_GLOBAL].trucks;
const getTruckModels = state => state[NAMESPACE_GLOBAL].truckModels;
const getCargoTypes = state => state[NAMESPACE_GLOBAL].cargoTypes;

const getTruckByTruckId = (state, id) => {
  const fake = {
    models: [],
    cargo_types: [],
  };
  return state[NAMESPACE_GLOBAL].trucks.find(truck => truck.id === parseInt(id, 10)) || fake;
};

const getTaxNumberInfo = state => state[NAMESPACE_GLOBAL].taxNumberInfo;

const getCities = state => state[NAMESPACE_GLOBAL].cities;

const getQuickOrderData = state => state[NAMESPACE_GLOBAL].quickOrderData;

export default {
  getTrucks,
  getTruckModels,
  getCargoTypes,
  getTruckByTruckId,
  getTaxNumberInfo,
  getCities,
  getQuickOrderData,
};
