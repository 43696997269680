import fetch from 'dva/fetch';
import router from 'umi/router';
import { formatMessage } from 'umi/locale';
import { notification, message } from 'antd';
import { get as _get, isEmpty, isNil } from 'lodash';

import { getAccessToken } from '@/utils/authority';
import { modalAction } from '@/components/common/SysUpdateModal';

import config from './config';

const notifyValidationMessages = ({ messages, _error, errors }) => {
  if (typeof messages === 'string') {
    message.error(_error);
  } else if (typeof _error === 'string') {
    message.error(_error);
  } else if (typeof _error === 'object') {
    message.error(formatMessage({ id: `error-code.${_error.code}` }));
  } else if (typeof errors === 'object') {
    Object.keys(errors).forEach(key => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach(error => message.error(error));
      } else {
        message.error(errors[key]);
      }
    });
  }
};

const notifyErrorMessages = (httpStatus, response) => {
  const HTTP_CODE_MESSAGES = {
    400: formatMessage({ id: 'code.message.400' }),
    401: formatMessage({ id: 'code.message.401' }),
    403: formatMessage({ id: 'code.message.403' }),
    404: formatMessage({ id: 'code.message.404' }),
    406: formatMessage({ id: 'code.message.406' }),
    410: formatMessage({ id: 'code.message.410' }),
    500: formatMessage({ id: 'code.message.500' }),
    502: formatMessage({ id: 'code.message.502' }),
    503: formatMessage({ id: 'code.message.503' }),
    504: formatMessage({ id: 'code.message.504' }),
  };
  const httpErrorMessage = HTTP_CODE_MESSAGES[httpStatus];
  if (response?.extensions?.code === 403) {
    notification.error({
      message: `${httpStatus}`,
      description: response.message,
    });
    return true;
  }
  if (httpErrorMessage) {
    notification.error({
      message: `${httpStatus}`,
      description: httpErrorMessage,
    });
    return true;
  }
  return false;
};

/**
 * For cases which needs to get data from 422 response
 */

const special422Case = (httpStatus, pureResponse) =>
  httpStatus === 422 && _get(pureResponse, '_error.code') === 'ARE-0009';

const handleHTTPStatus = async (response, shouldRedirect) => {
  const pureResponse = await response.json();
  const isNotifyErrorMessage = notifyErrorMessages(response.status, pureResponse);

  if (shouldRedirect && isNotifyErrorMessage) {
    if (response.status === 401) {
      router.push('/exception/401');
    } else if (response.status === 403) {
      router.push('/exception/403');
    } else if (response.status >= 404 && response.status < 422) {
      router.push('/exception/404');
    } else if (response.status >= 500 && response.status <= 504) {
      router.push('/exception/500');
    }
  }

  const isNeed422Response = special422Case(response.status, pureResponse);

  if ((response.status >= 200 && response.status < 300) || isNeed422Response) {
    return pureResponse;
  }

  if (response.status === 417) {
    modalAction.openModal();
    return response;
  }

  if (response.status === 422) {
    notifyValidationMessages(pureResponse);
    return null;
  }

  return response;
};

const request = (url, options, customHeaders, shouldRedirect) => {
  const cloneOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      ...customHeaders,
    },
    ...options,
  };

  const isInsOfFormData = cloneOptions.body instanceof FormData;
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(cloneOptions.method) && !isInsOfFormData) {
    cloneOptions.body = JSON.stringify(cloneOptions.body);
  }

  return fetch(url, cloneOptions).then(response => handleHTTPStatus(response, shouldRedirect));
};

//
// ──────────────────────────────────────────────────────────────── I ──────────
//   :::::: H T T P   M E T H O D S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────
//

const baseUrl = `${config.getServerPath()}/api/${config.version}`;

const getUrl = customUrl => (customUrl ? config.getServerPath() : baseUrl);

/**
 * GET
 */

const get = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldRedirect = true,
}) => {
  let queryString = Object.keys(params)
    .map(key => {
      if (Array.isArray(params[key])) {
        if (!isEmpty(params[key])) return key;
        return null;
      }
      return key;
    })
    .filter(key => !isNil(params[key]))
    .filter(key => params[key] !== '')
    .filter(key => params[key] !== -1)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  if (queryString.length > 0) {
    queryString = `?${queryString}`;
  }

  const url = `${getUrl(customUrl)}${endpoint}${queryString}`;
  const fetchParams = {
    method: 'GET',
    params,
  };

  return request(url, fetchParams, customHeaders, shouldRedirect);
};

/**
 * POST
 */

const post = async ({
  customUrl,
  endpoint,
  fixedUrl,
  params = {},
  customHeaders = {},
  shouldStringify = false,
  shouldRedirect = true,
}) => {
  const url = fixedUrl || `${getUrl(customUrl)}${endpoint}`;
  const fetchParams = {
    method: 'POST',
    body: shouldStringify ? JSON.stringify(params) : params,
  };

  return request(url, fetchParams, customHeaders, shouldRedirect);
};

/**
 * PUT
 */

const put = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldRedirect = true,
}) => {
  const url = `${getUrl(customUrl)}${endpoint}`;
  const fetchParams = {
    method: 'PUT',
    body: params,
  };

  return request(url, fetchParams, customHeaders, shouldRedirect);
};

/**
 * PATCH
 */

const patch = async ({
  customUrl,
  endpoint,
  params = {},
  customHeaders = {},
  shouldRedirect = true,
}) => {
  const url = `${getUrl(customUrl)}${endpoint}`;
  const fetchParams = {
    method: 'PATCH',
    body: params,
  };

  return request(url, fetchParams, customHeaders, shouldRedirect);
};

/**
 * DELETE
 */

const remove = async ({ customUrl, endpoint, customHeaders = {}, shouldRedirect = true }) => {
  const url = `${getUrl(customUrl)}${endpoint}`;
  const fetchParams = {
    method: 'DELETE',
  };

  return request(url, fetchParams, customHeaders, shouldRedirect);
};

export { request as default, get, post, put, patch, remove };
