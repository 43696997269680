// namespace
export const NAMESPACE_GLOBAL = 'global';
// truck
export const CHANGE_TRUCK_MODELS = 'changeTruckModels';
export const SAVE_TRUCKS = 'saveTrucks';
export const SAVE_TRUCK_MODELS = 'saveTruckModels';
export const SAVE_CARGO_TYPES = 'saveCargoTypes';
// tax number
export const FETCH_TAX_NUMBER_INFO = 'fetchTaxNumberInfo';
export const SAVE_TAX_NUMBER_INFO = 'saveTaxNumberInfo';
// layout
export const CHANGE_LAYOUT_COLLAPSED = 'changeLayoutCollapsed';
export const SAVE_QUICKORDER_DATA = 'saveQuickOrderData';
