export const GOOGLE_PLACE_TYPES_BLACKLIST = [
  'administrative_area_level_1',
  'colloquial_area',
  'country',
  'postal_code',
  'postal_code_prefix',
  'postal_code_suffix',
  'postal_town',
];

export const PLACE_IDS_BLACKLIST = ['ChIJ0T2NLikpdTERKxE8d61aX_E'];

export const ORDER_SHARED_TRUCK_TYPE = {
  WHOLE_TRUCK: 'whole_truck',
  SHARED_TRUCK: 'shared_truck',
};

export const ORDER_TYPE = {
  ENTERPRISE: 'enterprise',
  MARKETPLACE: 'marketplace',
  ORIGINAL_HYBRID: 'original_hybrid',
};

export const BROKER_TYPE = {
  SME: 'sme',
  ENTERPRISE: 'enterprise',
};

export const BROKER_ROLES = {
  OWNER: 'owner',
  COORDINATOR: 'coordinator',
};

export const FORM_VALIDATE_STATUS = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  VALIDATING: 'validating',
};

export const GOOGLE_AUTOCOMPLETE_KEY_NAMES = {
  FORMATTED_ADDRESS: 'formatted_address',
  DESCRIPTION: 'description',
};

export const BROKER_LOGINOW_E_CONTRACT = 'broker_loginow_e_contract';
export const BROKER_LOGINOW_E_CONTRACT_SUMMARY = 'broker_loginow_e_contract_summary';

export const HTML_CONTENT_TYPE = {
  ECONTRACT: 'econtract',
  TERMS_AND_CONDITION: 'T&C',
};

export default {
  ORDER_SHARED_TRUCK_TYPE,
  GOOGLE_AUTOCOMPLETE_KEY_NAMES,
};
