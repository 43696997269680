/* eslint-disable */
class Logger {
  static log(metaData) {
    console.log(metaData);
  }

  static warn(metaData) {
    console.warn(metaData);
  }

  static error(error) {
    console.error(error);
  }
}

export default Logger;
