// namespace reducer
export const NAMESPACE_PROFILE = 'profile';
// individual
export const FETCH_CURRENT_USER = 'fetchUser';
export const SAVE_CURRENT_USER = 'saveCurrentUser';
export const UPDATE_USER = 'updateUser';
export const UPLOAD_IDENTITY = 'uploadIdentity';
export const UPLOAD_IDENTITY_SUCCESS = 'uploadIdentitySuccess';
export const ARGEE_LOGINOW_CONTRACT = 'argeeLoginowContract';
export const ARGEE_LOGINOW_CONTRACT_SUCCESS = 'argeeLoginowContractSuccess';

// company
export const FETCH_COMPANY = 'fetchCompany';
export const SAVE_COMPANY = 'saveCompany';
export const UPDATE_BROKER_COMPANY = 'updateBrokerCompany';
export const CREATE_BROKER_COMPANY = 'createBrokerCompany';
export const UPLOAD_PHOTO_COMPANY = 'uploadPhotoCompany';
export const FETCH_ACCOUNTS = 'fetchAccounts';
export const SAVE_ACCOUNTS = 'saveAccounts';
export const CREATE_ACCOUNT = 'createAccount';
export const SAVE_ACCOUNT_AFTER_CREATE = 'saveAccountAfterCreate';
export const DELETE_ACCOUNT = 'deleteAccount';
export const SAVE_ACCOUNT_AFTER_DELETE = 'saveAccountAfterDelete';
export const UPDATE_ROLE_ACCOUNT = 'updateRoleAccount';
export const ACTIVE_ACCOUNT = 'activeAccount';
export const DEACTIVE_ACCOUNT = 'deactiveAccount';
export const UPDATE_ACCOUNT_SUCCESS = 'updateAccountSuccess';
export const SAVE_CURRENT_COMPANY = 'saveCurrentCompany';
export const CHANGE_NOTIFY_COUNT = 'changeNotifyCount';
export const RESET_ACCOUNT_AFTER_LOGOUT = 'resetAccountAfterLogout';
