const version = 'v2';
//
const development = {
  apiEndpoint: 'https://logivan.herokuapp.com',
  kcaEndpoint: 'https://api.logivan.com/mds/api',
  localStorageKey: 'logivan:user',
};

const production = {
  apiEndpoint: 'https://logivan.herokuapp.com',
  kcaEndpoint: 'https://api.logivan.com/mds/api',
  localStorageKey: 'logivan:user',
};

const staging = {
  apiEndpoint: 'https://logivan.herokuapp.com',
  kcaEndpoint: 'https://api.logivan.com/mds/api',
  localStorageKey: 'logivan:user',
};

const testbed = {
  apiEndpoint: 'https://logivan.herokuapp.com',
  kcaEndpoint: 'https://api.logivan.com/mds/api',
  localStorageKey: 'logivan:user',
};

const deployNow = {
  apiEndpoint: 'https://logivan.herokuapp.com',
  kcaEndpoint: 'https://api.logivan.com/mds/api',
  localStorageKey: 'logivan:user',
};

const configs = {
  development,
  production,
  staging,
  testbed,
  deployNow,
  version,
};
export default configs;
