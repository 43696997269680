export default {
  'validation.email.required': 'Please enter your email!',
  'validation.email.wrong-format': 'The email address is in the wrong format!',
  'validation.password.required': 'Please enter your password!',
  'validation.password.strength.medium': 'Strength: medium',
  'validation.password.strength.msg':
    "Please enter at least 6 characters and don't use passwords that are easy to guess.",
  'validation.password.strength.short': 'Strength: too short',
  'validation.password.strength.strong': 'Strength: strong',
  'validation.password.twice': 'The passwords entered twice do not match!',
  'validation.phone-number.required': 'Lose phone number',
  'validation.phone-number.wrong-format': 'Malformed phone number!',
};
