// namespace
export const NAMESPACE_LOGIN = 'login';
// login
export const LOGIN = 'login';
export const FETCH_AUTHENTICATION_TOKEN = 'fetchAuthenticationToken';
export const SAVE_LOGIN = 'saveLogin';
export const HANDLE_UPDATE_USER_REGISTER = 'handleUpdateUserRegister';
export const LOGOUT = 'logout';
export const REDIRECT_AFTER_LOGIN = 'redirectAfterLogin';

export const GET_OTP_ESMS = 'getOtpEsms';
export const GET_OTP_ESMS_SUCCESS = 'getOtpEsmsSuccess';
