export default {
  'quickorder.tab.personal': 'Personal Information',
  'quickorder.tab.commodity': 'Commodity Information',
  'quickorder.tab.transport': 'Transport Information',
  'quickorder.tab.receive-price': 'Get Price Now',
  'quickorder.title.personal': 'Register Personal Infomation',
  'quickorder.title.commodity': 'Commodity Information',
  'quickorder.title.transport': 'Select the vehicle type',
  'quickorder.title.location': 'Fill address pickup and dropoff',
  'quickorder.desc.personal': 'Leave your information to LOGIVAN to support you!',
  'quickorder.desc.commodity': 'Please tell LOGIVAN your infomation of commodity',
  'quickorder.desc.transport': 'Please choose one in three transport type',
  'quickorder.desc.location':
    'Please tell LOGIVAN address pickup and dropoff to find the best price',
  'quickorder.placeholder.name': 'Name',
  'quickorder.placeholder.phone': 'Phone',
  'quickorder.placeholder.length': 'length',
  'quickorder.placeholder.width': 'width',
  'quickorder.placeholder.height': 'height',
  'quickorder.placeholder.type': 'Order type',
  'quickorder.placeholder.weight': 'tons',
  'quickorder.placeholder.pickup': 'Cat Lai, Quan 2, Ho Chi Minh',
  'quickorder.placeholder.dropoff': 'Hai Chau, Da Nang',
  'quickorder.placeholder.truckmodels': 'Select truck models',
  'quickorder.label.quantity': 'Quantity',
  'quickorder.pickup.location': 'Pickup location',
  'quickorder.dropoff.location': 'Dropoff location',
  'quickorder.date_pickup': 'Date pickup',
  'quickorder.button.next': 'Next',
  'quickorder.button.prev': 'Back',
  'quickorder.button.select': 'Select',
  'quickorder.button.getprice': 'Get price now',
  'quickorder.button.bookacar': 'Book a car',
  'quickorder.alert.location': 'Please enter more detail address',
  'quickorder.alert.quantity': 'Please select truck quantity',
  'quickorder.alert.truckmodel': 'Please select truck models',
  'quickorder.text.example': 'Example',
  'quickorder.notes_input_size': 'Please input size of cargo to receive shares price from LOGIVAN',
};
