export default {
  'quickorder.tab.personal': 'Thông tin cá nhân',
  'quickorder.tab.commodity': 'Thông tin hàng hóa',
  'quickorder.tab.transport': 'Thông tin vận tải',
  'quickorder.tab.receive-price': 'Nhận giá ngay',
  'quickorder.title.personal': 'Đã có báo giá!',
  'quickorder.title.commodity': 'Bạn cần chở hàng gì vậy?',
  'quickorder.title.transport': 'Bạn cần tìm loại xe nào?',
  'quickorder.title.location': 'Bước cuối rồi!',
  'quickorder.desc.personal': 'Để lại thông tin để  LOGIVAN hỗ trợ bạn nhé!',
  'quickorder.desc.commodity': 'Vui lòng cho LOGIVAN biết thông tin hàng hóa của bạn',
  'quickorder.desc.transport': 'Hãy chọn 1 trong 3 loại xe dưới nhé',
  'quickorder.desc.location': ' Bạn muốn giao hàng đi đâu?',
  'quickorder.placeholder.name': 'Họ và tên',
  'quickorder.placeholder.phone': 'Số điện thoại',
  'quickorder.placeholder.length': 'dài',
  'quickorder.placeholder.width': 'rộng',
  'quickorder.placeholder.height': 'cao',
  'quickorder.placeholder.type': 'Loại hàng hóa',
  'quickorder.placeholder.weight': 'tấn',
  'quickorder.placeholder.pickup': 'Cảng Cát Lái, Quận 2, Hồ  Chí Minh',
  'quickorder.placeholder.dropoff': 'Hải Châu, Đà Nẵng',
  'quickorder.placeholder.truckmodels': 'Chọn loại thùng',
  'quickorder.label.quantity': 'Số  lượng',
  'quickorder.pickup.location': 'Đi từ',
  'quickorder.dropoff.location': 'Đến',
  'quickorder.date_pickup': 'Ngày bốc hàng',
  'quickorder.button.next': 'Tiếp theo',
  'quickorder.button.prev': 'Quay lại',
  'quickorder.button.select': 'Chọn',
  'quickorder.button.getprice': 'Nhận giá ngay',
  'quickorder.button.bookacar': 'Đặt xe',
  'quickorder.alert.location': 'Vui lòng nhập địa chỉ chi tiết hơn',
  'quickorder.alert.quantity': 'Vui lòng chọn số lượng xe',
  'quickorder.alert.truckmodel': 'Vui lòng chọn loại thùng',
  'quickorder.text.example': 'Ví dụ',
  'quickorder.notes_input_size': 'Vui lòng nhập kích thước hàng để có giá ghép hàng từ LOGIVAN',
};
