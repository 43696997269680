import React from 'react';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import Redirect from 'umi/redirect';
import { getAuthority, logout } from '@/utils/authority';
import RenderAuthorized from '@/components/auth/Authorized';

export default ({ children }) => {
  const auth = getAuthority();
  if (!auth) {
    logout();
  }
  if (isEmpty(auth) || !head(auth)) {
    logout();
  }
  const Authorized = RenderAuthorized(auth);
  return (
    <Authorized authority={children.props.route.authority} noMatch={<Redirect to="/user/login" />}>
      {children}
    </Authorized>
  );
};
