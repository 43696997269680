export default {
  'global.action': 'Action',
  'global.overview': 'Overview',
  'global.truck_type': 'Truck type',
  'global.truck_volume': 'Box dimensions size',
  'global.truck_quantity': 'Truck quantity',
  'global.params.truck_quantity': '{truck_quantity} truck(s)',
  'global.service_type': 'Service type',
  'global.all': 'All',
  'global.filter': 'Filter',
  'global.refresh': 'Refresh',
  'global.clear': 'Clear',
  'global.welcome_dashboard': 'Welcome dashboard',
  'global.dashboard_description': 'LOGIVAN helps you create, schedule, and manage your shipments',
  'global.create_new_order_now': 'Create new order now',
  'global.are-you-sure': 'Are you sure ?',
  'global.yes': 'Yes',
  'global.no': 'No',
  'global.ok': 'OK',
  'global.use': 'Use',
  'global.pay': 'Pay',
  'global.skip': 'Skip',
  'global.amount': 'Amount',
  'global.copy': 'Copy',
  'global.continue': 'Continue',
  'global.copied': 'Copied',
  'global.status': 'Status',
  'global.confirm': 'Confirm',
  'global.check-it-later': 'Later',
  'global.original-price': 'Original Price {originalPrice}',
  'global.transport-price': 'Transport price',
  'global.total-price': 'Total price',
  'global.promotion': 'Promotion',
  'global.include-VAT': 'Include VAT',
  'global.optional': 'Optional',
  'global.view_images': 'View images',
  'global.remove_images': 'Remove images',
  'global.add_images': 'Add images',
  'global.click_here_to_upload_images': 'Click here to upload images',
  'global.info': 'Information',
  'global.no_info': 'No information',
  'global.transport-info': 'Transport information',
  'global.fullname': 'Fullname',
  'global.no-name': 'No name',
  'global.phone_number': 'Phone number',
  'global.driver': 'Driver',
  'global.plate-number': 'Plate number',
  'global.no-info': 'No Information',
  'global.transport-capacity': 'Transport capacity',
  'global.length': 'length',
  'global.width': 'width',
  'global.height': 'height',
  'global.tons': 'tons',
  'global.download': 'Download',
  'global.export-data': 'Export data',
  'global.choose': 'Choose',
  'global.cancel': 'Cancel',
  'global.back': 'Back',
  'global.close': 'Close',
  'global.prev-month': 'Prev month',
  'global.next-month': 'Next month',
  'global.from-date': 'From date',
  'global.date': 'Date',
  'global.to-date': 'To date',
  'global.driver_name': 'Driver name',
  'global.locations': 'Locations',
  'global.types': 'Type',
  'global.dropoff_diffirent': 'unloading point',
  'global.hello': 'Hello',
  'global.export_data': 'Export',
  'global.account.owner': 'Account owner',
  'global.account.logivan_name': 'TRANSPORT TECHNOLOGY CO., LTD',
  'global.account.number': 'Account number',
  'global.account.bank': 'Bank',
  'global.account.bank_name':
    'TMCP Sai Gon Thuong Tin - CN Thu Do - Phong giao dich Hoan kiem (SACOMBANK)',
  'global.account.message_title': 'Message',
  'global.account.message_content': 'Name & Phone number - ID order (in order list)',
  'global.something_went_wrong': 'Something went wrong',
  'global.come_back_later': 'Come back later',
  'global.go_to_home': 'Go to Homepage',
  'global.read-and-agree-with-terms': 'I have read and agree to the terms from Logivan',
  'global.service-agreement': 'Contract for the agreement of goods transport service',
  'global.i-agree': 'I watched and agreed',
  'global.description-reset-password':
    'We have sent an email to reset your new password via {email}. Please check and see in your inbox.',
  'global.complete': 'Comeback Login page',
  'global.features_are_updating': 'Features are updating',
  'global.loginow_basic_terms_information': 'Loginow {text} information',
  'global.basic_terms': 'Basic terms',
  'global.sent': 'Sent',
  'global.by_continue_i_agree': 'By continue, I agree',
  'global.privacy_policy': 'Privacy Policy',
  'global.terms_of_use': 'Terms of use',
  'global.logivan_schedule': 'Logivan schedule!',
  'global.notify_lunar_new_year':
    'LOGIVAN will take Lunar New Year holidays from {dateStart} to the end of {dateEnd}, we will process orders placed from {dateProcess}.',
  'global.date_lunar_new_year_start': '23/01/2020 (29.12 Lunar )',
  'global.date_lunar_new_year_end': '29/01/2020 (Mùng 5 Tết)',
  'global.date_process': '30/01/2020 (Mùng 6 Tết)',
  'global.detail_address': 'Detail Address',
  'global.detail_address_ex': 'E.g. Number, Alley, Landmark gate',
  'global.try_again': 'Try again',
  'global.system_udpate': 'System update',
  'global.system_information_needs_to_be_updated': 'System information needs to be updated.',
  'global.please_try_again': 'Please try again.',
  'global.limit_image': 'Only allowed to upload up to {limit} images.',
  'global.drop_image_here': 'Drop image here...',
  'global.click_or_drag_image': 'Click or drag image to this area to upload',
  'global.deeplink_banner_description': 'Follow order everytime everywhere with Logivan App',
  'global.download_now': 'Download now',
  'global.call': 'Call',
  'global.reload': 'Reload',
  'global.wrong_tax_format': 'Wrong tax number format',
  'global.required': 'Required',
  'global.email_wrong_format': 'Email invalid',
  'global.loginow_service': 'Fast car reservation service',
  'global.terms_of_service': 'Terms of service',
  'global.see_more': 'More Detail',
  'global.summary_term_of_service': 'Terms of service summary',
  'global.show': 'Show',
  'global.tax_invoice': 'Tax Invoice',
  'global.location_details': 'Location details',
  'global.wrong_phone_format': 'Wrong mobile number format',
  'global.create': 'Create',
  'global.index': 'Index',
  'global.distributor_name': 'Distributor name',
};
