export default {
  'profile.account': 'Tài khoản',
  'profile.user-info': 'Thông tin cá nhân',
  'profile.company-profile': 'Thông tin doanh nghiệp',
  'profile.full-name': 'Họ và tên',
  'profile.email': 'Email',
  'profile.phone-number': 'Số điện thoại',
  'profile.identity-number': 'Số chứng minh nhân dân',
  'profile.status': 'Trạng thái',
  'profile.verified': 'Đã xác thực',
  'profile.unverified': 'Chưa xác thực',
  'profile.upload-id-number': 'Tải ảnh chứng minh nhân dân',
  'profile.notification-setting': 'Tuỳ chỉnh gửi tin',
  'profile.change-avatar': 'Đổi ảnh đại diện',
  'profile.allow-receive-notification': 'Cho phép nhận thông báo',
  'profile.allow-receive-notification-description':
    'Nhận thông báo khi có báo giá mới và những thông tin khác',
  'profile.please-fill-full-information': 'Vui lòng điền đầy đủ thông tin!',
  'profile.please-fill-full-basic-information': 'Vui lòng điền đầy đủ thông tin cơ bản!',
  'profile.update-info': 'Cập nhật thông tin',
  'profile.no-info': 'Chưa có thông tin',
  'profile.upload-identity-number': 'Tải chứng minh thư nhân dân',
  'profile.role': 'Vai trò',
  'profile.owner': 'Quản trị viên',
  'profile.coordinator': 'Điều phối viên',
  'profile.verify-company': 'Xác thực doanh nghiệp',
  'profile.description.company-1': 'Tải lên giấy tờ cần thiết để LOGIVAN xác thực doanh nghiệp',
  'profile.description.company-2':
    'Doanh nghiệp đã xác thực thì đơn hàng mới hiện thị lên hệ thống',
  'profile.description.account-1':
    'Các tài khoản trong cùng doanh nghiệp có thể xem được đơn hàng của nhau.',
  'profile.description.account-2':
    'Chỉ có Quản trị viên hoặc người tạo đơn mới có quyền sửa đơn hàng.',
  'profile.company-information': 'Thông tin doanh nghiệp',
  'profile.the-order-is-displayed-on-the-system-when-your-company-is-verified':
    'Doanh nghiệp đã xác thực thì đơn hàng mới hiện thị lên hệ thống',
  'profile.upload-the-business-registration-to-verify-the-company':
    'Tải lên giấy tờ cần thiết để LOGIVAN xác thực doanh nghiệp',
  'profile.business-registration': 'Giấy đăng kí kinh doanh',
  'profile.account-management': 'Quản lý nhân viên',
  'profile.in-house-accounts-can-view-each-others-orders.':
    'Các tài khoản trong cùng doanh nghiệp có thể xem được đơn hàng của nhau.',
  'profile.only-the-admin-or-creator-of-order-can-edit-the-order.':
    'Chỉ có Quản trị viên hoặc người tạo đơn mới có quyền sửa đơn hàng.',
  'profile.add-member': 'Thêm tài khoản',
  'profile.member': 'Thành viên',
  'profile.verification': 'Xác minh',
  'profile.banner-not-create-company':
    'Tài khoản của bạn chưa tạo doanh nghiệp. Vui lòng tạo doanh nghiệp.',
  'profile.banner-company-unverified':
    'Tài khoản của bạn đang được LOGIVAN xử lý. Bạn sẽ nhận được thông báo khi doanh nghiệp được xác thực.',
  'profile.banner-company-not-upload-document':
    'Bạn chưa tải lên giấy tờ cần thiết để xác thực tài khoản.',
  'profile.upload-image': 'Tải ngay',
  'profile.create-company': 'Tạo công ty',
  'profile.upload-business-registration-certificate': 'Tải giấy đăng kí kinh doanh',
  'profile.drag-the-photo-here-or-click-to-select-a-photo':
    'Kéo thả ảnh vào đây hoặc ấn để chọn ảnh',
  'profile.complete-register': 'Hoàn tất đăng ký thông tin',
  'profile.company-management': 'Quản lý doanh nghiệp',
  'profile.verified-24h': 'Tài khoản của bạn sẽ được xác thực bởi LOGIVAN trong tối đa 24h nữa.',
  'profile.account-role':
    'Bạn có thể tạo đơn hàng mới ngay, hoặc thêm tài khoản khác vào doanh nghiệp.',
  'profile.active_account': 'Kích hoạt tài khoản',
  'profile.inactive_account': 'Khóa tài khoản',
  'profile.update_account_success': 'Cập nhật tài khoản thành công',
  'profile.identity_card_id': 'Chứng minh nhân dân / thẻ căn cước',
  'profile.front_identity_card': 'Mặt trước',
  'profile.backside_identity_card': 'Mặt sau',
  'profile.identity_is_not_full': 'Vui lòng upload 2 mặt của CMND',
  'profile.identity_notes':
    '*Nếu giấy tờ của bạn có bất kỳ thay đổi nào, vui lòng liên hệ hỗ trợ để được cập nhật sớm nhất',
  'profile.representative': 'Người đại diện',
  'profile.additional_email_addresses': 'Bổ sung địa chỉ email',
  'profile.additional_email_addresses_desc':
    'Từ 01/06/2020 để tăng tính minh bạch trong giao dịch với chủ hàng. Logivan sẽ gửi các thông tin qua email đăng ký của bạn.',
  'profile.information_will_be_kept_confidential_according_to_the_logivan_policy':
    'Thông tin sẽ được bảo mật theo chính sách Logivan',
  'profile.receive_electronic_invoices': 'Nhận hóa đơn điện tử',
  'profile.receiving_electronic_transport_contract': 'Nhận hợp đồng vận chuyển điện tử',
};
