import error from './vi-VN/errorCode';
import exception from './vi-VN/exception';
import form from './vi-VN/form';
import global from './vi-VN/global';
import globalHeader from './vi-VN/globalHeader';
import layout from './vi-VN/layout';
import login from './vi-VN/login';
import measurement from './vi-VN/measurement';
import menu from './vi-VN/menu';
import order from './vi-VN/order';
import profile from './vi-VN/profile';
import pwa from './vi-VN/pwa';
import quickOrder from './vi-VN/quickOrder';
import settingDrawer from './vi-VN/settingDrawer';
import shipment from './vi-VN/shipment';
import validation from './vi-VN/validation';

export default {
  ...error,
  ...exception,
  ...form,
  ...global,
  ...globalHeader,
  ...layout,
  ...login,
  ...measurement,
  ...menu,
  ...order,
  ...profile,
  ...pwa,
  ...quickOrder,
  ...settingDrawer,
  ...shipment,
  ...validation,
};
