import * as types from '@/actionTypes/menuTypes';

const namespace = types.NAMESPACE_MENU;

export const getMenuData = ({ routes, authority }) => ({
  type: `${namespace}/${types.GET_MENU_DATA}`,
  payload: {
    routes,
    authority,
  },
});

export const save = ({ menuData, breadcrumbNameMap }) => ({
  type: types.SAVE,
  payload: {
    menuData,
    breadcrumbNameMap,
  },
});

export default {
  getMenuData,
  save,
};
