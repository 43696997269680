import error from './en-US/errorCode';
import exception from './en-US/exception';
import form from './en-US/form';
import global from './en-US/global';
import globalHeader from './en-US/globalHeader';
import layout from './en-US/layout';
import login from './en-US/login';
import measurement from './en-US/measurement';
import menu from './en-US/menu';
import order from './en-US/order';
import profile from './en-US/profile';
import pwa from './en-US/pwa';
import quickOrder from './en-US/quickOrder';
import settingDrawer from './en-US/settingDrawer';
import shipment from './en-US/shipment';
import validation from './en-US/validation';

export default {
  ...error,
  ...exception,
  ...form,
  ...global,
  ...globalHeader,
  ...layout,
  ...login,
  ...measurement,
  ...menu,
  ...order,
  ...profile,
  ...pwa,
  ...quickOrder,
  ...settingDrawer,
  ...shipment,
  ...validation,
};
