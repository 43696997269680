import get from 'lodash/get';
import numeral from 'numeral';
import { formatMessage } from 'umi/locale';

import { TABLE_PAGE_SIZE } from '@/constants/tableConstants';
import Logger from './logger';

export const doNothing = () => {};

export function upperCaseChar(string, idx = 0) {
  if (!string || typeof string !== 'string') return string;
  return (
    string.slice(0, idx) + string.charAt(idx).toUpperCase() + string.slice(idx + 1, string.length)
  );
}

export function checkValueZero(val) {
  /* eslint-disable-next-line */
  return !!val ? val : 0;
}

export function getCurrentLang() {
  const lg = localStorage.getItem('umi_locale') || 'vi';
  const formatLg = lg.split('-');
  return Array.isArray(formatLg) ? formatLg[0] : 'vi';
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export const formatPaging = (pages = {}) => ({
  current: pages.current_page,
  total: pages.total_count,
});

/**
 * Pagination object
 */

export const configPagination = (pagination, pageSize = TABLE_PAGE_SIZE) => {
  const config = { ...pagination };
  config.total = get(pagination, 'total_count', 0);
  config.showQuickJumper = true;
  config.pageSize = pageSize;
  config.current = get(pagination, 'current_page', 0);
  return config;
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const download = (text, params) => {
  try {
    const { to_date, from_date } = params;
    const csv = text;
    let dataURI;
    let blob;
    if (csv === null) return null;

    const filename = `congno-${from_date}-${to_date}.csv`;
    const type = isSafari() ? 'application/csv' : 'text/csv';

    if (!csv.match(/^data:text\/csv/i)) {
      const BOM = '\uFEFF';
      blob = new Blob([BOM ? '\uFEFF' : '', csv], { type });
      dataURI = `data:${type};charset=utf-8,${BOM}${csv}`;
    }

    const URL = window.URL || window.webkitURL;
    const buildURI =
      typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', buildURI);
    link.setAttribute('download', filename);
    link.click();
    return null;
  } catch (e) {
    return Logger.log(e);
  }
};

export const generateRandomKey = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

export const formatAddress = address => address.replace(/, Việt Nam|, Viet Nam|, Vietnam/, '');

export const hideEmail = email => {
  const groupMatch = email.match(/^\w{4}(\w+)/);
  const strHide = get(groupMatch, '1', '');
  return email.replace(strHide, '*******');
};

export const checkCargoWeight = (_, value, callback) => {
  if (value < 0.3) callback(formatMessage({ id: 'form.minimum-cargo-weight' }));
  else callback();
};

export const getDistanceBetween = (p1 = { lat: 0, lng: 0 }, p2 = { lat: 0, lng: 0 }) => {
  const rad = x => (x * Math.PI) / 180;

  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

export const normalizeNumber = (value, prevValue = 1) => {
  const number = Number(value);
  if (Number.isNaN(number)) return prevValue;
  return value;
};

export const removeAccent = str =>
  (str || '')
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    .replace(/đ/g, 'd')
    .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
    .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
    .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
    .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
    .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
    .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
    .replace(/Đ/g, 'D');

export const randomString = (len = 5) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < len; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const getDurationTime = miliseconds => {
  let duration = miliseconds / 1000;
  const days = Math.floor(duration / 86400);
  duration %= 86400;
  const hours = Math.floor(duration / 3600);
  duration %= 3600;
  const minutes = Math.floor(duration / 60);

  const str = [];

  if (days) str.push(`${days} ngày`);
  if (hours) str.push(`${hours} giờ`);
  if (minutes) str.push(`${minutes} phút`);

  return str.join(' ');
};
export const filterOption = (inputValue, option, field = 'value') => {
  const optionValue = removeAccent(option?.props?.[field]).toLocaleLowerCase();

  const value = removeAccent(inputValue).toLocaleLowerCase();
  return optionValue.indexOf(value) !== -1;
};

export const toFixedNumeral = (number = 0, format = '0[.][000]') => numeral(number).format(format);

export const clearStorage = (excepts = ['serverPath']) => {
  const exceptValues = {};
  excepts.forEach(item => {
    if (localStorage.getItem(item)) exceptValues[item] = localStorage.getItem(item);
  });
  localStorage.clear();
  Object.keys(exceptValues).forEach(item => localStorage.setItem(item, exceptValues[item] || ''));
};

export const dedupeNumbers = arr => [...new Set(arr.filter(Number.isFinite))];
