import { post } from '@/utils/request';

export const hasuraLogin = params =>
  post({
    fixedUrl: 'https://0qv8utl6jk.execute-api.ap-southeast-1.amazonaws.com/ActionLoginBroker',
    params: {
      input: params,
    },
  });

export const hasuraAcceptInvite = params =>
  post({
    fixedUrl: 'https://0qv8utl6jk.execute-api.ap-southeast-1.amazonaws.com/ActionInviteAccept',
    params: {
      input: params,
    },
  });

export const hasuraLoginSendOtp = params =>
  post({
    fixedUrl: 'https://0qv8utl6jk.execute-api.ap-southeast-1.amazonaws.com/ActionOtpSend',
    params: {
      input: params,
    },
  });

export const hasuraLoginVerifyOtp = params =>
  post({
    fixedUrl: 'https://0qv8utl6jk.execute-api.ap-southeast-1.amazonaws.com/ActionOtpVerify',
    params: {
      input: params,
    },
    shouldRedirect: false,
  });

export const getAuthenticationToken = ({ phoneNumber, otpCode, otpToken }) =>
  post({
    customUrl: true,
    shouldRedirect: false,
    endpoint: '/broker/brokers/register',
    params: {
      broker: {
        app_id: APP_ID,
        otp_code: otpCode,
        otp_token: otpToken,
        phone_number: phoneNumber,
      },
    },
  });

export const verifyOTPEsms = (phoneNumber, otpCode) =>
  post({
    endpoint: '/account_regis/accounts/verify_otp',
    customUrl: true,
    shouldRedirect: false,
    params: {
      phone_number: phoneNumber,
      otp_code: otpCode,
      app_id: APP_ID,
    },
  });
