export default {
  'global.action': 'Thao tác',
  'global.overview': 'Tổng quan',
  'global.truck_type': 'Loại xe',
  'global.truck_volume': 'Thể tích thùng',
  'global.truck_quantity': 'Số lượng xe',
  'global.params.truck_quantity': '{truck_quantity} xe',
  'global.service_type': 'Loại dịch vụ',
  'global.all': 'Tất cả',
  'global.filter': 'Tra cứu',
  'global.refresh': 'Cập nhật',
  'global.clear': 'Xóa bộ lọc',
  'global.welcome_dashboard': 'Chào mừng đến bảng điều khiển',
  'global.dashboard_description': 'LOGIVAN giúp bạn tạo, lên kế hoạch và quản lý đơn hàng của mình',
  'global.create_new_order_now': 'Tạo đơn hàng ngay',
  'global.are-you-sure': 'Bạn có chắc chắn?',
  'global.yes': 'Có',
  'global.no': 'Không',
  'global.ok': 'OK',
  'global.use': 'Sử dụng',
  'global.pay': 'Thanh toán',
  'global.skip': 'Bỏ qua',
  'global.amount': 'Số tiền',
  'global.copy': 'Sao chép',
  'global.continue': 'Tiếp tục',
  'global.copied': 'Đã sao chép',
  'global.status': 'Trạng thái',
  'global.driver_name': 'Tên tài xế',
  'global.confirm': 'Xác nhận',
  'global.check-it-later': 'Để sau',
  'global.original-price': 'Giá gốc {originalPrice}',
  'global.transport-price': 'Giá vận chuyển',
  'global.total-price': 'Tổng tiền',
  'global.promotion': 'Khuyến mãi',
  'global.include-VAT': 'Bao gồm VAT',
  'global.info': 'Thông tin',
  'global.no_info': 'Không có thông tin',
  'global.transport-info': 'Thông tin vận tải',
  'global.optional': 'Không bắt buộc',
  'global.view_images': 'Xem hình ảnh',
  'global.remove_images': 'Xóa hình ảnh',
  'global.add_images': 'Thêm ảnh',
  'global.click_here_to_upload_images': 'Bấm vào đây để tải ảnh',
  'global.fullname': 'Họ và tên',
  'global.no-name': 'Không có tên',
  'global.dropoff_diffirent': 'điểm dỡ khác',
  'global.locations': 'Địa điểm',
  'global.types': 'Kiểu',
  'global.phone_number': 'Số điện thoại',
  'global.driver': 'Tài xế',
  'global.plate-number': 'Biển số xe',
  'global.no-info': 'Chưa có thông tin',
  'global.transport-capacity': 'Năng lực vận tải',
  'global.length': 'dài',
  'global.width': 'rộng',
  'global.height': 'cao',
  'global.tons': 'tấn',
  'global.download': 'Tải xuống',
  'global.export-data': 'Xuất dữ liệu',
  'global.choose': 'Chọn',
  'global.cancel': 'Huỷ',
  'global.back': 'Quay về',
  'global.close': 'Đóng',
  'global.prev-month': 'Tháng trước',
  'global.next-month': 'Tháng tới',
  'global.from-date': 'Từ ngày',
  'global.date': 'Ngày',
  'global.to-date': 'Đến ngày',
  'global.hello': 'Xin chào',
  'global.export_data': 'Xuất dữ liệu',
  'global.account.owner': 'Chủ tài khoản',
  'global.account.logivan_name': 'Công Ty TNHH CÔNG NGHỆ VẬN TẢI LGV',
  'global.account.number': 'Số tài khoản',
  'global.account.bank': 'Ngân hàng',
  'global.account.bank_name':
    'TMCP Sài Gòn Thương Tín - CN Thủ Đô - Phòng giao dịch Hoàn kiếm (SACOMBANK)',
  'global.account.message_title': 'Nội dung chuyển tiền',
  'global.account.message_content': 'Tên & SĐT khách - ID Đơn hàng (trong phần danh sách đơn hàng)',
  'global.something_went_wrong': 'Có lỗi xảy ra',
  'global.come_back_later': 'Vui lòng quay lại sau!',
  'global.go_to_home': 'Trở về trang chủ',
  'global.read-and-agree-with-terms': 'Tôi đã đọc và đồng ý với các điều khoản từ Logivan.',
  'global.service-agreement': 'Hợp đồng thỏa thuận sử dụng dịch vụ<br />vận tải hàng hóa',
  'global.i-agree': 'Tôi đã xem và đồng ý',
  'global.description-reset-password':
    'Chúng tôi đã gửi một email khởi tạo lại mật khẩu mới của bạn qua địa chỉ {email}. Xin vui lòng kiểm tra và xem trong hộp thư đến của bạn.',
  'global.comeback-login': 'Trở về trang đăng nhập',
  'global.features_are_updating': 'Tính năng đang cập nhật',
  'global.terms_summary': 'Điều khoản cơ bản',
  'global.terms_of_use_of_the_service': 'Điều khoản sử dụng dịch vụ',
  'global.loginow_basic_terms_information':
    'Bằng việc đặt đơn hàng, đồng nghĩa bạn đồng ý với {text} dịch vụ LOGINOW.',
  'global.basic_terms': 'điều khoản sử dụng',
  'global.sent': 'Đã gửi',
  'global.by_continue_i_agree': 'Bằng việc tiếp tục, tôi đồng ý',
  'global.privacy_policy': 'Chính sách bảo mật',
  'global.terms_of_use': 'Điều khoản sử dụng',
  'global.logivan_schedule': 'Thông báo lịch làm việc của Logivan!',
  'global.notify_lunar_new_year':
    'LOGIVAN sẽ nghỉ Tết Nguyên Đán từ ngày {dateStart} đến hết ngày {dateEnd}, chúng tôi sẽ xử lý đơn hàng đã đặt từ {dateProcess}.',
  'global.date_lunar_new_year_start': '23/01/2020 (29.12 Âm Lịch)',
  'global.date_lunar_new_year_end': '29/01/2020 (Mùng 5 Tết)',
  'global.date_process': '30/01/2020 (Mùng 6 Tết)',
  'global.detail_address': 'Địa chỉ chi tiết',
  'global.detail_address_ex': 'E.g. Số nhà, hẻm, cổng ở cảng',
  'global.try_again': 'Thử lại',
  'global.system_udpate': 'Cập nhật hệ thống',
  'global.system_information_needs_to_be_updated': 'Thông tin hệ thống cần được cập nhật.',
  'global.please_try_again': 'Vui lòng thử lại.',
  'global.limit_image': 'Chỉ được upload tối đa {limit} ảnh.',
  'global.drop_image_here': 'Thả ảnh vào đây',
  'global.click_or_drag_image': 'Nhấp chuột hoặc kéo thả ảnh vào đây để tải ảnh lên',
  'global.deeplink_banner_description': 'Trực Tiếp Xem Hành Trình Trên Ứng Dụng LOGIVAN.',
  'global.download_now': 'Tải ngay',
  'global.call': 'Gọi',
  'global.reload': 'Làm mới',
  'global.wrong_tax_format': 'Mã số thuế sai định dạng',
  'global.required': 'Bắt buộc',
  'global.email_wrong_format': 'Email sai định dạng',
  'global.loginow_service': 'Dịch vụ đặt xe nhanh với',
  'global.terms_of_service': 'Điều khoản sử dụng',
  'global.see_more': 'Xem thêm',
  'global.summary_term_of_service': 'Tóm tắt điều khoản dịch vụ',
  'global.show': 'Hiển thị',
  'global.tax_invoice': 'Hóa đơn điện tử',
  'global.location_details': 'Địa chỉ chi tiết',
  'global.wrong_phone_format': 'Số điện thoại sai định dạng',
  'global.create': 'Tạo',
  'global.index': 'STT',
  'global.distributor_name': 'Nhà phân phối',
};
