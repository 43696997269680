export default {
  'profile.account': 'Account',
  'profile.user-info': 'User info',
  'profile.company-profile': 'Company profile',
  'profile.full-name': 'Full name',
  'profile.email': 'Email',
  'profile.phone-number': 'Phone number',
  'profile.identity-number': 'Identity number',
  'profile.status': 'Status',
  'profile.verified': 'Verified',
  'profile.unverified': 'Unverified',
  'profile.upload-id-number': 'Upload id number image',
  'profile.notification-setting': 'Notification setting',
  'profile.change-avatar': 'Change avatar',
  'profile.allow-receive-notification': 'Allow receive notification',
  'profile.allow-receive-notification-description':
    'Receive notification when have bidding or something news',
  'profile.please-fill-full-information': 'Please fill full information!',
  'profile.please-fill-full-basic-information': 'Please fill full basic information!',
  'profile.update-info': 'Update information',
  'profile.no-info': 'No information',
  'profile.upload-identity-number': 'Upload identity number',
  'profile.role': 'Role',
  'profile.owner': 'Owner',
  'profile.coordinator': 'Coordinator',
  'profile.verify-company': 'Verify company',
  'profile.description-company-1': 'Tải lên giấy tờ cần thiết để LOGIVAN xác thực doanh nghiệp',
  'profile.description-company-2':
    'Doanh nghiệp đã xác thực thì đơn hàng mới hiện thị lên hệ thống',
  'profile.description.account-1':
    'Các tài khoản trong cùng doanh nghiệp có thể xem được đơn hàng của nhau.',
  'profile.description.account-2':
    'Chỉ có Quản trị viên hoặc người tạo đơn mới có quyền sửa đơn hàng.',
  'profile.company-information': 'Company information',
  'profile.the-order-is-displayed-on-the-system-when-your-company-is-verified':
    'The order is displayed on the system when your company is verified',
  'profile.upload-the-business-registration-to-verify-the-company':
    'Upload the business registration to verify the company',
  'profile.business-registration': 'Business registration',
  'profile.account-management': 'Account management',
  'profile.in-house-accounts-can-view-each-others-orders.':
    'In-house accounts can view each others orders.',
  'profile.only-the-admin-or-creator-of-order-can-edit-the-order.':
    'Only the Admin or creator of order can edit the order.',
  'profile.add-member': 'Add member',
  'profile.member': 'Member',
  'profile.verification': 'Verification',
  'profile.banner-not-create-company':
    'Your company has not yet been created. Please create a company.',
  'profile.banner-company-unverified':
    'Your company is being processed by LOGIVAN. You will receive a notification when the company is authenticated.',
  'profile.banner-company-not-upload-document':
    'You have not uploaded documents to verify your company.',
  'profile.upload-image': 'Upload image',
  'profile.create-company': 'Create company',
  'profile.upload-business-registration-certificate': 'Upload business registration certificate',
  'profile.drag-the-photo-here-or-click-to-select-a-photo':
    'Drag the photo here or click to select a photo',
  'profile.complete-register': 'Completed register',
  'profile.company-management': 'Company management',
  'profile.verified-24h': 'Your account will be authenticated by LOGIVAN for up to 24h.',
  'profile.account-role': 'You can create new orders now, or add more accounts to your company.',
  'profile.active_account': 'Active account',
  'profile.inactive_account': 'Inactive account',
  'profile.update_account_success': 'Update account successfully',
  'profile.identity_card_id': 'Identity Card/ID',
  'profile.front_identity_card': 'Front page',
  'profile.backside_identity_card': 'Backsize',
  'profile.identity_is_not_full': 'Please update 2 side of identity',
  'profile.identity_notes':
    '*If your documents have any changes, please contact support to be updated as soon as possible',
  'profile.additional_email_addresses': 'Additional email addresses',
  'profile.additional_email_addresses_desc':
    'From 06/01/2020 to increase transparency in transactions with shippers. Logivan will send the news via your registered email.',
  'profile.information_will_be_kept_confidential_according_to_the_logivan_policy':
    'Information will be kept confidential according to the Logivan policy',
  'profile.receive_electronic_invoices': 'Receiving electronic invoices',
  'profile.receiving_electronic_transport_contract': 'Receiving electronic transport contract',
};
