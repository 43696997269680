import { doNothing } from '@/utils/utils';
import * as types from '@/actionTypes/orderTypes';
import { LIMIT_PAGE, DEFAULT_PAGE_NUMBER, TABLE_PAGE_SIZE } from '@/constants/tableConstants';

const namespace = types.NAMESPACE_ORDER;

//
// ──────────────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R   L I S T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────
//

export const fetchOrders = params => ({
  type: `${namespace}/${types.FETCH_ORDERS}`,
  payload: {
    params: {
      ...params,
      page: params?.page || DEFAULT_PAGE_NUMBER,
      limit: TABLE_PAGE_SIZE,
    },
  },
});

export const saveListOrder = ({ orders }) => ({
  type: [types.SAVE_LIST_ORDERS],
  payload: {
    orders,
  },
});

export const filterOrdersByParams = params => ({
  type: `${namespace}/${types.FILTER_ORDERS}`,
  payload: {
    params,
  },
});

export const updateOrderInList = ({ order }) => ({
  type: [types.UPDATE_ORDER_IN_LIST],
  payload: {
    order,
  },
});

//
// ──────────────────────────────────────────────────────────────── I ──────────
//   :::::: O R D E R   D E T A I L : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────
//

export const updateOrderStatus = (orderID, status) => ({
  type: `${namespace}/${types.UPDATE_ORDER_STATUS}`,
  payload: {
    orderID,
    status,
  },
});

export const cancelOrder = (orderID, callback = doNothing) => ({
  type: `${namespace}/${types.CANCEL_ORDER}`,
  payload: {
    orderID,
    callback,
  },
});

export const saveOrderDetail = ({ order }) => ({
  type: [types.SAVE_ORDER_DETAIL],
  payload: {
    order,
  },
});

export const getOrderById = orderID => ({
  type: `${namespace}/${types.GET_ORDER_BY_ID}`,
  payload: {
    orderID,
  },
});

export const createOrder = (order, createOrderSuccess) => ({
  type: `${namespace}/${types.CREATE_ORDER}`,
  payload: {
    order,
    createOrderSuccess,
  },
});

export const fetchOrderTemplates = () => ({
  type: `${namespace}/${types.FETCH_ORDER_TEMPLATES}`,
});

export const setOrderTemplates = ({ order_templates }) => ({
  type: [types.SET_ORDER_TEMPLATES],
  payload: {
    order_templates,
  },
});

export const fetchOrderSuggestions = () => ({
  type: `${namespace}/${types.FETCH_ORDER_SUGGESTIONS}`,
});

export const setOrderSuggestions = ({ order_suggestions }) => ({
  type: [types.SET_ORDER_SUGGESTIONS],
  payload: {
    order_suggestions,
  },
});

export const fetchInstantPrice = values => ({
  type: `${namespace}/${types.FETCH_INSTANT_PRICE}`,
  payload: {
    values,
  },
});

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: S H I P M E N T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const fetchShipments = orderId => ({
  type: `${namespace}/${types.FETCH_SHIPMENTS}`,
  payload: {
    orderId,
  },
});

export const saveShipments = shipments => ({
  type: types.SAVE_SHIPMENTS,
  payload: {
    shipments,
  },
});

export const fetchShipmentDetail = ({ orderId, shipmentId, params }) => ({
  type: `${namespace}/${types.FETCH_SHIPMENT_DETAIL}`,
  payload: {
    orderId,
    shipmentId,
    params,
  },
});

export const updateShipmentInList = ({ updatedShipment }) => ({
  type: types.UPDATE_SHIPMENT_IN_LIST,
  payload: {
    updatedShipment,
  },
});

export const saveShipmentDocuments = shipmentDocs => ({
  type: types.SAVE_SHIPMENT_DOCUMENTS,
  payload: {
    shipmentDocs,
  },
});

export const saveLocations = ({ locations }) => ({
  type: types.SAVE_LOCATIONS,
  payload: {
    locations,
  },
});

export const saveShipmentBiddings = ({ shipmentBidding }) => ({
  type: types.SAVE_SHIPMENT_BIDDINGS,
  payload: {
    shipmentBidding,
  },
});

export const resetShipmentBiddings = () => ({
  type: `${namespace}/${types.RESET_SHIPMENT_BIDDINGS}`,
});

export const clearPropsInShipments = () => ({
  type: `${namespace}/${types.CLEAR_PROPS_IN_SHIPMENTS}`,
});

//
// ──────────────────────────────────────────────────────── I ──────────
//   :::::: B I D D I N G S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────
//

export const fetchBiddings = (
  orderId,
  { amount, isFilterSelected, limit = LIMIT_PAGE, page = DEFAULT_PAGE_NUMBER }
) => ({
  type: `${namespace}/${types.FETCH_BIDDINGS}`,
  payload: {
    orderId,
    params: {
      page,
      limit,
      amount,
      isFilterSelected,
    },
  },
});

export const fetchBiddingsSuccess = ({ listBiddings, pageListBiddings }) => ({
  type: types.FETCH_BIDDINGS_SUCCESS,
  payload: {
    listBiddings,
    pageListBiddings,
  },
});

export const fetchSelectedBiddings = orderId => ({
  type: `${namespace}/${types.FETCH_SELECTED_BIDDINGS}`,
  payload: {
    orderId,
  },
});

export const fetchSelectedBiddingsSuccess = listSelectedBiddings => ({
  type: types.FETCH_SELECTED_BIDDINGS_SUCCESS,
  payload: {
    listSelectedBiddings,
  },
});

export const selectBidding = ({ orderId, biddingId, callback }) => ({
  type: `${namespace}/${types.SELECT_BIDDING}`,
  payload: {
    orderId,
    biddingId,
    callback,
  },
});

export const selectBiddingSuccess = bidding => ({
  type: types.SELECT_BIDDING_SUCCESS,
  payload: {
    bidding,
  },
});

export const deselectBidding = ({ orderId, biddingId, callback }) => ({
  type: `${namespace}/${types.DESELECT_BIDDING}`,
  payload: {
    orderId,
    biddingId,
    callback,
  },
});

export const deselectBiddingSuccess = bidding => ({
  type: types.DESELECT_BIDDING_SUCCESS,
  payload: {
    bidding,
  },
});

export const acceptBidding = orderID => ({
  type: `${namespace}/${types.ACCEPT_BIDDING}`,
  payload: {
    orderID,
  },
});

export const acceptBiddingSuccess = order => ({
  type: types.ACCEPT_BIDDING_SUCCESS,
  payload: {
    order,
  },
});

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: T R U C K   Q U O T A T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

/**
 * Quotation
 */

export const fetchTruckQuotations = orderId => ({
  type: `${namespace}/${types.FETCH_TRUCK_QUOTATIONS}`,
  payload: {
    orderId,
  },
});

export const saveTruckQuotations = quotations => ({
  type: types.SAVE_TRUCK_QUOTATIONS,
  payload: {
    quotations,
  },
});

export const changeTruckQuotation = ({ orderId, quoId }) => ({
  type: `${namespace}/${types.CHANGE_TRUCK_QUOTATION}`,
  payload: {
    orderId,
    quoId,
  },
});

export const updateChangedQuotation = quotation => ({
  type: types.UPDATE_CHANGED_QUOTATION,
  payload: {
    quotation,
  },
});

/**
 * Quotation Detail
 */

export const getQuotationDetail = ({ orderId, quoId, promoId }) => ({
  type: `${namespace}/${types.GET_QUOTATION_DETAIL}`,
  payload: {
    orderId,
    quoId,
    promoId,
  },
});

export const saveQuotationDetail = quoDetail => ({
  type: types.SAVE_QUOTATION_DETAIL,
  payload: {
    quoDetail,
  },
});

export const removePromotion = ({ orderId, quoId }) => ({
  type: `${namespace}/${types.REMOVE_PROMOTION}`,
  payload: {
    orderId,
    quoId,
  },
});

export const updateRemovedPromotion = quoDetail => ({
  type: types.UPDATE_REMOVED_PROMOTION,
  payload: {
    quoDetail,
  },
});

/**
 * Order Notes and Images
 */

export const updateOrderNotesAndImages = ({ orderId, notes, images }) => ({
  type: `${namespace}/${types.UPDATE_ORDER_NOTES_AND_IMAGES}`,
  payload: {
    orderId,
    notes,
    images,
  },
});

/**
 * Promotions
 */

export const getPromotions = code => ({
  type: `${namespace}/${types.GET_PROMOTIONS}`,
  payload: {
    code,
  },
});

export const savePromotions = promotions => ({
  type: types.SAVE_PROMOTIONS,
  payload: {
    promotions,
  },
});

/**
 * Payment Methods
 */

export const getPaymentMethods = ({ orderId, fromAreaId, totalPrice }) => ({
  type: `${namespace}/${types.GET_PAYMENT_METHODS}`,
  payload: {
    orderId,
    fromAreaId,
    totalPrice,
  },
});

export const savePaymentMethods = paymentMethods => ({
  type: types.SAVE_PAYMENT_METHODS,
  payload: {
    paymentMethods,
  },
});

/**
 * Accept Order
 */

export const acceptOrder = ({ orderId, quoId, params }) => ({
  type: `${namespace}/${types.ACCEPT_ORDER}`,
  payload: {
    orderId,
    quoId,
    params,
  },
});

export const updateOrderDetailAfterAccept = orderDetail => ({
  type: types.UPDATE_ORDER_DETAIL_AFTER_ACCEPT,
  payload: {
    orderDetail,
  },
});

/**
 * Bank Account
 */

export const getBankAccounts = () => ({
  type: `${namespace}/${types.GET_BANK_ACCOUNTS}`,
});

export const saveBankAccounts = bankAccounts => ({
  type: types.SAVE_BANK_ACCOUNTS,
  payload: {
    bankAccounts,
  },
});

/**
 * Clear Order Detail Props
 */

export const clearPropsInHybridOrder = () => ({
  type: `${namespace}/${types.CLEAR_PROPS_IN_HYBRID_ORDER}`,
});
