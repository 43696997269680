"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = renderRoutes;

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var RouteInstanceMap = {
  get: function get(key) {
    return key._routeInternalComponent;
  },
  has: function has(key) {
    return key._routeInternalComponent !== undefined;
  },
  set: function set(key, value) {
    key._routeInternalComponent = value;
  }
}; // Support pass props from layout to child routes

var RouteWithProps = function RouteWithProps(_ref) {
  var path = _ref.path,
      exact = _ref.exact,
      strict = _ref.strict,
      _render = _ref.render,
      location = _ref.location,
      rest = _objectWithoutProperties(_ref, ["path", "exact", "strict", "render", "location"]);

  return _react.default.createElement(_reactRouterDom.Route, {
    path: path,
    exact: exact,
    strict: strict,
    location: location,
    render: function render(props) {
      return _render(_objectSpread({}, props, rest));
    }
  });
};

function getCompatProps(props) {
  var compatProps = {};

  if (__UMI_BIGFISH_COMPAT) {
    if (props.match && props.match.params && !props.params) {
      compatProps.params = props.match.params;
    }
  }

  return compatProps;
}

function withRoutes(route) {
  if (RouteInstanceMap.has(route)) {
    return RouteInstanceMap.get(route);
  }

  var Routes = route.Routes;
  var len = Routes.length - 1;

  var Component = function Component(args) {
    var render = args.render,
        props = _objectWithoutProperties(args, ["render"]);

    return render(props);
  };

  var _loop = function _loop() {
    var AuthRoute = Routes[len];
    var OldComponent = Component;

    Component = function Component(props) {
      return _react.default.createElement(AuthRoute, props, _react.default.createElement(OldComponent, props));
    };

    len -= 1;
  };

  while (len >= 0) {
    _loop();
  }

  var ret = function ret(args) {
    var _render2 = args.render,
        rest = _objectWithoutProperties(args, ["render"]);

    return _react.default.createElement(RouteWithProps, _extends({}, rest, {
      render: function render(props) {
        return _react.default.createElement(Component, _extends({}, props, {
          route: route,
          render: _render2
        }));
      }
    }));
  };

  RouteInstanceMap.set(route, ret);
  return ret;
}

function renderRoutes(routes) {
  var extraProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var switchProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return routes ? _react.default.createElement(_reactRouterDom.Switch, switchProps, routes.map(function (route, i) {
    if (route.redirect) {
      return _react.default.createElement(_reactRouterDom.Redirect, {
        key: route.key || i,
        from: route.path,
        to: route.redirect,
        exact: route.exact,
        strict: route.strict
      });
    }

    var RouteRoute = route.Routes ? withRoutes(route) : RouteWithProps;
    return _react.default.createElement(RouteRoute, {
      key: route.key || i,
      path: route.path,
      exact: route.exact,
      strict: route.strict,
      render: function render(props) {
        var childRoutes = renderRoutes(route.routes, {}, {
          location: props.location
        });

        if (route.component) {
          var compatProps = getCompatProps(_objectSpread({}, props, extraProps));
          var newProps = window.g_plugins.apply('modifyRouteProps', {
            initialValue: _objectSpread({}, props, extraProps, compatProps),
            args: {
              route: route
            }
          });
          return _react.default.createElement(route.component, _extends({}, newProps, {
            route: route
          }), childRoutes);
        } else {
          return childRoutes;
        }
      }
    });
  })) : null;
}