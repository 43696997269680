/* eslint-disable no-dupe-keys */
export default {
  'order.order': 'Order',
  'order.trip': 'Trip',
  'order.pickup-location': 'Pickup location',
  'order.dropoff-location': 'Dropoff location',
  'order.information': 'Order Information',
  'order.order-detail': 'Order detail',
  'order.order-weight': 'Order weight(tons)',
  'order.created-date': 'Created date',
  'order.pickup_datetime': 'Pickup datetime',
  'order.pickup_date': 'Pickup date',
  'order.pickup_time': 'Pickup time',
  'order.pickup-datetime': 'Pickup datetime',
  'order.dropoff-datetime': 'Dropoff datetime',
  'order.creator': 'Creator',
  'order.order-list': 'Order list',
  'order.cargo-type': 'Order type',
  'order.pickup_info': 'Pickup info',
  'order.dropoff_info': 'Dropoff info',
  'order.truck_type_id': 'Truck type',
  'order.truck_model_id': 'Truck model',
  'order.choose-truck-body': 'Choose truck body',
  'order.price-for-truck-quantity': 'Price for {quantity} truck(s)',
  'order.bidding_status': 'Status',
  'order.cargo_types': 'Cargo types',
  'order.title.cargo_weight': 'Cargo weight',
  'order.title.cargo_size': 'Cargo size',
  'order.cargo_weight': '{weight} tons',
  'order.text.truck_quantity': 'Truck quantity',
  'order.truck_quantity': '{quantity} cars',
  'order.order-location': 'Order location',
  'order.order-information': 'Order information',
  'order.truck_request_info': 'Truck request information',
  'order.price': 'Price',
  'order.images': 'Images',
  'order.newly_created': 'Newly created',
  'order.already_have_truck': 'Already have truck',
  'order.cancel_order': 'Cancel order',
  'order.pickup_address': 'Pick up address',
  'order.pickup_province': 'Pick up province',
  'order.dropoff_province': 'Drop up province',
  'order.dropoff_address': 'Drop off address',
  'order.example_address': 'Example: Cat Lat',
  'order.order_id': 'Order ID',
  'order.shipment_id': 'Shipment ID',
  'order.search': 'Search',
  'order.most_recent_trips': 'All most recent trips',
  'order.trips_are_running': 'The trips are running',
  'order.trips_are_scheduled_to_run': 'The trips are scheduled to run',
  'order.trips_have_been_completed': 'The trips have been completed',
  'order.accomplished': 'Accomplished',
  'order.scheduling': 'Scheduling is in progress',
  'order.created_order_broker': 'Created order broker',
  'order.example_broker': 'Example: Nguyen Van A',
  'order.address_is_incorrect': 'Address is incorrect',
  'order.select_your_registered_trips_from_the_list': 'Select your registered trips from the list',
  'order.add_a_new_trip': 'Add a new trip',
  'order.your_trip_name': 'Your trip {{title}}',
  'order.order_with_new_trip': 'Order with new trip',
  'order.shipment-detail': 'Shipment detail',
  'order.preferences': 'Preferences',
  'order.is_insurance_requested': 'Request insurance',
  'order.hide_contact': 'Hide contact from drivers',
  'order.is_tax_invoice_requested': 'Request tax invoice',
  'order.is_whole_truck': 'Request whole truck',
  'order.is_share_truck': 'Request share truck',
  'order.additional info': 'Additional info',
  'order.shipment_photo': 'Shipment photo',
  'order.business_photo': 'Business photo',
  'order.trip-detail': 'Trip detail',
  'order.pickup-location-do': 'Delivery order of pickup location',
  'order.dropoff-location-do': 'Delivery order of dropoff location',
  'order.pickup': 'pickup',
  'order.dropoff': 'dropoff',
  'order.delivery-order-code': 'Delivery Order code',
  'order.code-product-description': 'Code / Product description',
  'order.total-unit': 'Total unit',
  'order.total-weight': 'Total weight',
  'order.unit': 'Unit',
  'order.weight': 'Weight',
  'order.accepted': 'Accepted',
  'order.pending': 'Pending',
  'order.show-bidding': 'Show bidding',
  'order.list-biddings': 'List biddings',
  'order.order-bidding': 'Order bidding',
  'order.accept-bidding': 'Accept bidding',
  'order.reject-bidding': 'Reject bidding',
  'order.bidding-created-at': 'Bidding created at',
  'order.billing': 'Billing',
  'order.total-billing': 'Total billing',
  'order.total-billing-of-year': 'Total billing of year',
  'order.total-billing-of-month': 'Total billing of month',
  'order.cost-type': 'Cost type',
  'order.fee-name': 'Fee name',
  'order.quantity': 'Quantity',
  'order.total-price': 'Total Price',
  'order.tax-rate': 'VAT',
  'order.total-price-with-tax': 'Total Price (VAT)',
  'order.assigned-person': 'Assigned Person',
  'order.posted': 'Posted',
  'order.expired': 'Expired',
  'order.cancelled': 'Cancelled',
  'order.completed': 'Completed',
  'order.upcoming': 'Upcoming',
  'order.isunloading': 'Is unloading',
  'order.unloadingpoint': 'Coming to the unloading point',
  'order.loadinggoods': 'Loading goods',
  'order.copy': 'Click to copy',
  'order.nodriver': 'No data',
  'order.pickuppoint': 'Moving to pickup point',
  'order.accept_order': 'Driver accepted',
  'order.has_bidding': 'Has bidding',
  'order.bidding_quoted': 'Has bidding',
  'order.bidding_accepted': 'Accepted',
  'order.shipment_new': 'Found truck',
  'order.shipment_in_transit': 'In transit',
  'order.stt': 'ID',
  'order.route': 'Route',
  'order.shipment-documents': 'View detail shipment images',
  'order.quotation-transport': 'Quotation',
  'order.order-confirm': 'Order confirm',
  'order.whole_truck': 'Whole truck',
  'order.shared_truck': 'Shared truck',
  'order.does_not_include_vat': 'Does not include VAT',
  'order.enter_cargo_dimensions_to_get_shared_truck_price':
    'Enter cargo dimensions to get shared truck price',
  'order.LGV_have_not_supported_for_this_routes_and_cargo':
    'LOGIVAN have not supported for this routes and cargo.',
  'order.please_press': 'Please press',
  'order.to_view_order_and_LGV_will': 'to view order and LOGIVAN will',
  'order.direct_bidding': 'send direct bidding',
  'order.for_you': 'for you',
  'order.create_order_success': 'Congratulations, You Got a New Car!',
  'order.text_logivan_contact_for_you': 'LOGIVAN will contact you immediately to lock the car!',
  'order.text_back_home': 'Back to order list in',
  'order.sum': 'Sum',
  'order.pretax_price': 'Pre Tax price',
  'order.sub_total_price': 'Cước vận chuyển',
  'order.preview.pickup_location': 'Pickup location',
  'order.preview.dropoff_location': 'Dropoff location',
  'order.preview.pickup_date': 'Pickup date',
  'order.preview.truck_type': 'Truck type',
  'order.preview.cargo_type': 'Cargo type',
  'order.preview.notes': 'Notes',
  'order.message.choice_shared_or_whole': 'Please choice Whole truck or Shared truck',
  'order.text.no_suitable_price': 'There is no suitable price for this vehicle',
  'order.text.no_shared_price_for_many_truck': 'LGV has not been able to support grafting on 1 car',
  'order.text.with_this_payload_you_can_only_ride_the_vehicle':
    'With this payload you can only ride the vehicle',
  'order.title.bidding': 'Bidding for order',
  'order.btn.ready_for_transport': 'Ready transport',
  'order.btn.select_bidding': 'Select bidding',
  'order.btn.unselect_bidding': 'Unselect',
  'order.btn.detail': 'Detail',
  'order.btn.close': 'Close',
  'order.btn.cancel': 'Cancel',
  'order.btn.confirm': 'Confirm',
  'order.title.bidding_detail': 'Bidding detail',
  'order.title.shipment_info': 'Shipment information',
  'order.title.transport_capacity': 'Transport capacity',
  'order.title.sorry': 'Sorry!',
  'order.title.confirm_quote': 'Confirm quote!',
  'order.title.filter': 'Filter',
  'order.desc.suggested_price': 'Suggested price',
  'order.desc.quote_price': 'Quote price for',
  'order.desc.date_bidding': 'Date bidding',
  'order.desc.bidded_truck_for_order': 'Vehicles have quoted shipping prices for orders',
  'order.desc.error_not_enough_cars':
    'The number of transport vehicles is still not enough to carry out order acceptance. You need to find enough cars to continue.',
  'order.desc.notification_enough_cars':
    'The number of vehicles transported from quotation is sufficient as required. Are you sure to accept the quote for this order.',
  'order.desc.not_bidding_for_order': 'The order has no quotation',
  'order.desc.truck-quantity': 'Truck quantity',
  'order.desc.temporary-price': 'Temporary price',
  'order.desc.warning-total-bid':
    'You cannot select this quote because the number of vehicles will exceed the total number of vehicles required.',
  'order.car': 'car',
  'order.bidding_status.selected': 'Selected',
  'order.bidding_status.pending': 'Pending',
  'order.bidding_status.rejected': 'Rejected',
  'order.bidding_status.cancelled': 'Cancelled',
  'order.today': 'Today',
  'order.dayago': '{day} days ago',
  'order.quotations': 'Transport Quotations',
  'order.notes': 'Order notes',
  'order.confirm': 'Confirm',
  'order.account-number': 'Account number',
  'order.account-name': 'Account name',
  'order.brand': 'Brand',
  'order.choose-payment-method': 'Choose payment method',
  'order.payment-method': 'Payment method',
  'order.payment-method-unsupported': 'This method is not supported for over 1.000.000 VND',
  'order.not-choose-payment-method-yet': 'You have not choose payment method yet.',
  'order.not-able-to-use-promotion':
    'You are not able to use promotion for this order. Please refer terms and conditions of promotion.',
  'order.pay-with': 'Pay with',
  'order.payment-info': 'Payment information',
  'order.payment-content': 'Payment content',
  'order.waiting-for-payment': 'Waiting for payment',
  'order.paid': 'Paid',
  'order.cash-on-delivery': 'Cash on delivery(Cash)',
  'order.payment-for-order': 'Payment for order #{orderId}',
  'order.bank-transfer': 'Bank transfer',
  'order.your-promotion': 'Your promotion',
  'order.enter-promotion-codes': 'Enter promotion codes',
  'order.have-no-promotion': 'Unfortunately, you have no promotion yet',
  'order.transfer-before-lgv-looking-truckers':
    'Please transfer the transport amount before Logivan look for truckers.',
  'order.waiting-for-confirm-from-lgv-after-payment':
    'If you have completed the transfer. Please wait for confirmation from Logivan.',
  'order.bank-transfer-content': 'Order {orderId} - {user} - {phoneNumber}',
  'order.transport-services': 'Transport services',
  'order.get-quotations': 'Get Quotations',
  'order.enter_full_cargo_size': 'Please input cargo size',
  'order.warning_cargo_size': 'Cargo size exceeds allowed limit (L)14.3m x (W)2.4m x (H)2.7m',
  'order.input_cargo_description': 'Please input cargo description',
  'order.input_address': 'Input address',
  'order.lose_recipient': 'Lose recipient',
  'order.pickup_and_dropoff_to_show_list':
    'Please input pickup and dropoff location to show list of services',
  'order.select_truck_model': 'Select truck model',
  'order.required_truck_model': 'Truck model is required',
  'order.required_truck': 'Truck is required',
  'order.date_time_invalid': 'Pickup datetime invalid',
  'order.what_dose_it_mean': 'What dose it mean?',
  'order.information_cargo_size_description':
    'Cargo size information for calculating the price of the shared truck',
  'order.warning_share_truck_must_have_cargo_size':
    'The type of share truck service requires size of cargo to calculate the shipping cost.',
  'order.warning_distance_is_invalid': 'This route is not suitable for car transplant services',
  'order.are_you_sure_with_order_information':
    'Are you sure you get the quote for an order with a volume of {cargoWeight} and a quantity of {truckQuantity}?',
  'order.need_at_least_x_vehicles_for_every_x_tons_order':
    'Need at least {quantity} vehicles for every {weight} tons order',
  'order.minimum-cargo-weight': 'Sorry you! Minimum weight of the product is 0.3 tons',
  'order.required-cargo-weight': 'Input cargo weight',
  'order.pickup_recipient': 'Pickup recipient',
  'order.dropoff_recipient': 'Dropoff recipient',
  'order.title_reason_cancel_order': 'Your order is very important to the driver',
  'order.subtitle_reason_cancel_order':
    'Please share your reason to help improve order service quality',
  'order.message_cancel_success': 'Order #{orderId} cancelled',
  'order.logivan_will_contact_you_as_soon_as_possible':
    'LOGIVAN will contact you as soon as possible',
  'order.move_hour_later': 'Move to {number} hours later',
  'order.select_other_time': 'Chọn thời gian khác',
  'order.status': 'Order status',
  'order.away_from_dropped_off': 'Away from dropped off',
  'order.nearly_arrived': 'Nearly arrived',
  'order.address': 'Order #{orderId}',
  'order.invalid_code': "The link you've entered has expired",
  'order.copy_share_link': 'The shared link has been copied',
  'order.logivan_will_contact_you_to_get_billing_information':
    'Logivan will contact you to get billing information',
  'order.require_location': 'Please input location',
  'order.require_detail_location':
    'For the most accurate price, please enter the address in more detail. Example: Cat Lai Port, District 2, HCM',
  'order.send_econtract': 'Send terms of use to my email',
  'order.truck_model': 'Truck model',
  'order.time_pickup': 'Pickup time',
  'order.time_dropoff': 'Dropoff time',
  'order.location_list': 'Locations',
  'order.order_id_title': 'Order #{orderId}',
  'order.order_document': 'Order Document',
  'order.order_from_south': 'South Order',
  'order.order_from_central': 'Central Order',
  'order.order_from_north': 'North Order',
  'order.do_the_goods_load_at_the_port': 'Do the goods load at the port?',
  'order.pickup_address_placeholder': 'Cai Lan Port, Quang Ninh',
  'order.dropoff_address_placeholder': 'Pho Noi A Industrial Zone, Hung Yen',
  'order.cod_amount_placeholder': 'Input COD amount',
  'order.add_dropoff_address': 'Add dropoff address',
  'order.delivery_order_code_to_dropoff': 'Number of delivery order: {number}',
  'order.delivery_order_code_list': 'List delivery order',
  'order.delivery_order_total_weight': 'Total weight: {number} tons',
  'order.sku_code': 'SKU',
  'order.add_delivery_order_item': 'Add item',
  'order.sku_check_label': 'Has SKU',
  'order.cargo_type_name_label': 'Name',
  'order.delivery_orders_display_by_location': 'By location',
  'order.delivery_orders_display_by_all': 'All',
  'order.description': 'Description',
  'order.delivery_orders_section_summary': '{quantity} DO (Total weight: {weight} tons)',
  'order.total_quantity': 'Total',
  'order.warning_create_multi_dropoff_order':
    'Order with multiple dropoff locations cannot be editted after create, do you want to recheck it?',
  'order.recheck': 'Recheck',
  'order.continue_create': 'Continue create',
};
