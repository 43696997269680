import { NAMESPACE_PROFILE } from '@/actionTypes/profileTypes';

export const getCompanyId = state => state[NAMESPACE_PROFILE]?.user?.broker_company?.id;
export const getUser = state => state[NAMESPACE_PROFILE].user;
export const getCompany = state => state[NAMESPACE_PROFILE].company;
export const getListAccounts = state => state[NAMESPACE_PROFILE].listAccounts;
export const getPagesListAccounts = state => state[NAMESPACE_PROFILE].pagesListAccounts;
export const getAddressBooks = state =>
  state[NAMESPACE_PROFILE].user?.broker_company?.address_books || {};

export const getPackingUnits = state =>
  state[NAMESPACE_PROFILE].user?.broker_company?.packing_units || [
    { id: 67, unit: 'Iron', length: null, width: null, height: null, description: null },
    { id: 68, unit: 'Pallet', length: null, width: null, height: null, description: null },
  ];

export default {
  getUser,
  getCompany,
  getCompanyId,
  getListAccounts,
  getPagesListAccounts,
};
