import Logger from '@/utils/logger';

export const getUser = () => {
  try {
    let user = null;
    if (localStorage.development) {
      user = JSON.parse(localStorage.development);
    } else if (sessionStorage.development) {
      user = JSON.parse(sessionStorage.development);
    }
    return user;
  } catch (error) {
    Logger.log(error);
    return {};
  }
};

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str) {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  const authorityString =
    typeof str === 'undefined' ? localStorage.getItem('antd-pro-authority') : str;
  const user = getUser();
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (!localStorage.hasura && !sessionStorage.hasura) {
    return null;
  }
  if (typeof authority === 'string') {
    return [authority, user?.broker_type];
  }
  return [...(authority || []), user?.broker_type];
}

export const logout = (cb = false) => {
  delete localStorage.access_token;
  delete localStorage.development;
  delete localStorage.banner;
  delete sessionStorage.development;

  delete localStorage.hasura;
  delete sessionStorage.hasura;
  delete sessionStorage.hasura_access_token;
  delete localStorage.hasura_access_token;

  delete localStorage['antd-pro-authority'];
  if (cb) cb();
};
export function setAuthority(authority) {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
}

export const saveCredentials = (user, data) => {
  const { authentication_token: accessToken, ...info } = user;

  if (data) {
    localStorage.hasura = JSON.stringify(data);
    sessionStorage.hasura = JSON.stringify(data);
    localStorage.hasura_access_token = data.refresh_token;
    sessionStorage.hasura_access_token = data.refresh_token;
  }

  if (accessToken) {
    localStorage.access_token = accessToken;
    sessionStorage.access_token = accessToken;
  }

  localStorage.development = JSON.stringify(info);
  sessionStorage.development = JSON.stringify(info);
};

export const getAccessToken = () =>
  sessionStorage.access_token ? sessionStorage.access_token : localStorage.access_token;

export const getHasuraAccessToken = () =>
  sessionStorage.hasura_access_token
    ? sessionStorage.hasura_access_token
    : localStorage.hasura_access_token;
