export default {
  'form.get-captcha': 'Get Captcha',
  'form.add-row': 'Add row',
  'form.captcha.second': 'sec',
  'form.optional': ' (optional) ',
  'form.skip': 'Skip',
  'form.back': 'Back',
  'form.submit': 'Submit',
  'form.save': 'Save',
  'form.send': 'Send',
  'form.edit': 'Edit',
  'form.required': 'Required',
  'form.cancel': 'Cancel',
  'form.delete': 'Delete',
  'form.create-success': 'Create success.',
  'form.edit-success': 'Edit success.',
  'form.upload-success': 'Upload success.',
  'form.email.placeholder': 'Email',
  'form.name.placeholder': 'Name',
  'form.password.placeholder': 'Password',
  'form.confirm-password.placeholder': 'Confirm password',
  'form.phone-number.placeholder': 'Phone number',
  'form.verification-code.placeholder': 'Verification code',
  'form.tax_number.label': 'Tax number',
  'form.tax_number.placeholder': 'Give the target a tax number',
  'form.date.label': 'Start and end date',
  'form.date.placeholder.start': 'Start date',
  'form.date.placeholder.end': 'End date',
  'form.goal.label': 'Goal description',
  'form.goal.placeholder': 'Please enter your work goals',
  'form.standard.label': 'Metrics',
  'form.standard.placeholder': 'Please enter a metric',
  'form.client.label': 'Client',
  'form.client.label.tooltip': 'Target service object',
  'form.client.placeholder':
    'Please describe your customer service, internal customers directly @ Name / job number',
  'form.company.name.label': 'Company name',
  'form.company.name.placeholder': 'Target company name',
  'form.company.address.label': 'Company address',
  'form.company.address.placeholder': 'Target company address',
  'form.company.add-member-success': 'Add member success',
  'form.company.delete-member-success': 'Delete member success',
  'form.company.not-found': 'Can not find company',
  'form.company.change-role-success': 'Change role success',
  'form.company.active-success': 'Active account success',
  'form.company.deactive-success': 'Deactive account success',
  'form.invites.label': 'Inviting critics',
  'form.invites.placeholder': 'Please direct @ Name / job number, you can invite up to 5 people',
  'form.weight.label': 'Weight',
  'form.weight.placeholder': 'Please enter weight',
  'form.public.label': 'Target disclosure',
  'form.public.label.help': 'Customers and invitees are shared by default',
  'form.public.radio.public': 'Public',
  'form.public.radio.partially-public': 'Partially public',
  'form.public.radio.private': 'Private',
  'form.publicUsers.placeholder': 'Open to',
  'form.publicUsers.option.A': 'Colleague A',
  'form.publicUsers.option.B': 'Colleague B',
  'form.publicUsers.option.C': 'Colleague C',
  // form order
  'form.order.validate-location': 'Please select result from dropdown.',
  'form.order.product-code': 'Product code',
  'form.order.description': 'Description',
  'form.order.validate-do': 'You must create DO code before create item',
  'form.order.no-code': 'No code',
  'form.order.enter-code': 'Enter code',
  'form.order.select-do': 'Select Delivery Order',
  'form.order.clear-do': 'Clear Delivery Order',
  'form.order.select-do-title': 'Select delivery order to unload at each location',
  'form.order.add-do': 'Add Deliver Order',
  'form.order.add-dropoff-location': 'Add dropoff location',
  'form.order.add-pickup-location': 'Add pickup location',
  'form.order.additional-info': 'Additional information',
  'form.order.offer-price': 'Offer price',
  'form.order.create-new-order': 'Create new order',
  'form.order.error-not-found-company': 'Can not find company.',
  'form.order.validate-unit': 'Please enter unit',
  'form.order.add-packaging-spec': 'Add packaging spec',
  'form.order.add-new-packaging-spec': 'Add new packaging spec',
  'form.order.size': 'Size',
  'form.order.weight-per-unit': 'Weight (kg) per unit',
  'form.order.has-do': 'Have Delivery Order',
  'form.order.no-do': 'No Delivery Order',
  'form.order.accepted-bidding-success': 'Accepted bidding success',
  'form.order.need-truck-type': 'Please select truck type',
  'form.order.need-truck-model': 'Please select truck model',
  'form.order.notes': 'Notes',
  'form.order.estimated_cost': 'Estimated cost',
  'form.order.estimated_cost.unit': 'Unit',
  'form.order.estimated_cost.number': 'Estimated number of units',
  'form.order.estimated_cost.selling': 'Unit selling price',
  'form.order.created_at': 'Created at',
  'form.quickorder.name': 'Please input your name',
  'form.quickorder.phone': 'Please input your phone number',
  'form.quickorder.size': 'Number must be greater than zero',
  'form.quickorder.truckModel': 'Please select truck models',
  'form.choose_province_city': 'Choose province/city',
  'form.choose_district': 'Choose district/prefecture',
};
