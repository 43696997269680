import { message } from 'antd';
import { formatMessage } from 'umi/locale';
import { isEmpty } from 'lodash';

import {
  getListOrder,
  updateOrderStatus,
  cancelOrder,
  fetchOrderTemplates,
  createOrder,
  hasuraGetOrderById,
  hasuraGetShipmentById,
  selectBidding,
  deselectBidding,
  acceptBidding,
  fetchInstantPrice,
  getTruckQuotations,
  changeTruckQuotation,
  getQuotationDetail,
  removePromotion,
  updateOrderNotesAndImages,
  getPromotions,
  getPaymentMethods,
  acceptOrder,
  getBankAccounts,
  ORDER_STATUSES,
} from '@/services/order';
import Logger from '@/utils/logger';
import profileActions from '@/actions/profileActions';
import loadingSelectors from '@/selectors/loadingSelectors';
import profileSelectors from '@/selectors/profileSelectors';
import * as types from '@/actionTypes/orderTypes';
import * as actions from '@/actions/orderActions';
import { doNothing, configPagination } from '@/utils/utils';
import { order_suggestions } from '@/constants/data';
import { DEFAULT_PAGE_NUMBER, LIMIT_PAGE } from '@/constants/tableConstants';
import { ORDER_STATUS } from '@/constants/statusConstants';
import { ORDER_TYPE } from '@/constants/typeConstants';

export default {
  namespace: types.NAMESPACE_ORDER,
  state: {
    orders: {
      list: [],
      pagination: {},
      filterValues: {},
    },
    orderDetail: null,
    order_templates: [],
    order_suggestions: [],
    locations: [],
    biddings: [],
    listBiddings: [],
    listSelectedBiddings: [],
    pageListBiddings: [],
    shipmentBidding: {},
  },
  effects: {
    //
    // ──────────────────────────────────────────────────────────── I ──────────
    //   :::::: O R D E R   L I S T : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────
    //

    *[types.FETCH_ORDERS]({ payload }, { call, put }) {
      const { params } = payload;
      const response = yield call(getListOrder, params);
      if (!response) {
        return;
      }
      const { pages, orders } = response;
      yield put(
        actions.saveListOrder({
          orders: {
            filterValues: params,
            list: orders,
            pagination: configPagination(pages),
          },
        })
      );
    },

    *[types.FILTER_ORDERS]({ payload }, { call, put }) {
      const { params = {} } = payload;
      if (!params.page) {
        params.page = 1;
      }
      params.limit = 25;
      const response = yield call(getListOrder, params);
      if (response) {
        const { pages, orders } = response;
        yield put(
          actions.saveListOrder({
            pages,
            orders: orders.sort((s1, s2) => s2.id - s1.id),
            params,
          })
        );
      } else {
        yield put(actions.saveListOrder({ params }));
      }
    },

    //
    // ──────────────────────────────────────────────────────────────── I ──────────
    //   :::::: O R D E R   D E T A I L : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────────
    //

    *[types.GET_ORDER_BY_ID]({ payload }, { call, put }) {
      yield put(actions.saveLocations({ locations: [] }));
      try {
        const { orderID } = payload;
        const response = yield call(hasuraGetOrderById, orderID);
        if (response) {
          const { status: nums, ...order } = response;
          order.status = ORDER_STATUSES[nums];
          const { order_type, status } = order;
          yield put(actions.saveOrderDetail({ order }));
          if (order_type !== ORDER_TYPE.ORIGINAL_HYBRID) {
            const params = { limit: LIMIT_PAGE, page: DEFAULT_PAGE_NUMBER };
            if (status === ORDER_STATUS.BIDDING_ACCEPTED) {
              params.isFilterSelected = true;
            }
          }
        }
      } catch (e) {
        Logger.log(e);
      }
    },

    *[types.UPDATE_ORDER_STATUS]({ payload }, { call, put }) {
      const { orderID, status } = payload;
      const response = yield call(updateOrderStatus, orderID, status);
      if (isEmpty(response)) {
        return;
      }
      yield put(actions.updateOrderInList({ order: response.order }));
    },

    *[types.CANCEL_ORDER]({ payload }, { call, put }) {
      const { orderID, callback } = payload;
      const response = yield call(cancelOrder, orderID);
      if (isEmpty(response)) {
        return;
      }
      callback();
      yield put(actions.updateOrderInList({ order: response.order }));
    },

    *[types.FETCH_ORDER_TEMPLATES]({}, { call, put, select, take }) {
      if (yield select(state => loadingSelectors.getIsFetchingCurrentUser(state))) {
        yield take(profileActions.saveCurrentUser({}).type);
      }
      const user = yield select(state => profileSelectors.getUser(state));
      Logger.log(user);
      const { broker_company_id = '' } = yield select(state => profileSelectors.getUser(state));
      if (broker_company_id) {
        const response = yield call(fetchOrderTemplates, broker_company_id);
        if (response) {
          yield put(actions.setOrderTemplates({ order_templates: response.order_templates }));
        }
      }
    },

    *[types.FETCH_ORDER_SUGGESTIONS]({}, { put, select, take }) {
      if (yield select(state => loadingSelectors.getIsFetchingCurrentUser(state))) {
        yield take(profileActions.saveCurrentUser({}).type);
      }
      yield put(actions.setOrderSuggestions({ order_suggestions }));
    },

    *[types.CREATE_ORDER]({ payload }, { call }) {
      try {
        const { order, createOrderSuccess = doNothing } = payload;
        const response = yield call(createOrder, order);
        if (response) {
          createOrderSuccess(response);
          Logger.log(response);
        }
      } catch (e) {
        Logger.log(e);
      }
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: S H I P M E N T : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    *[types.FETCH_SHIPMENTS]({ payload }, { call, put }) {
      try {
        const { orderId } = payload;
        const response = yield call(hasuraGetOrderById, orderId);
        if (response) {
          const { shipments, ...order } = response;
          const finalShipments = shipments.map(shipment => ({ ...shipment, order }));
          yield put(actions.saveShipments(finalShipments));
          return finalShipments;
        }
        return [];
      } catch (error) {
        Logger.log(error);
        return [];
      }
    },

    *[types.FETCH_SHIPMENT_DETAIL]({ payload }, { call, put }) {
      try {
        const { orderId, shipmentId, params } = payload;
        const response = yield call(hasuraGetShipmentById, { orderId, shipmentId, params });
        const shipment = response.data.data.shipments_by_pk;
        if (response) {
          yield put(actions.updateShipmentInList({ updatedShipment: shipment }));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: B I D D I N G S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    *[types.SELECT_BIDDING]({ payload }, { call, put }) {
      try {
        const { orderId, biddingId, callback } = payload;
        const response = yield call(selectBidding, orderId, biddingId);
        if (isEmpty(response)) {
          return;
        }
        const { bidding } = response;
        yield put(actions.selectBiddingSuccess(bidding));
        callback();
      } catch (e) {
        Logger.log(e);
      }
    },

    *[types.DESELECT_BIDDING]({ payload }, { call, put }) {
      try {
        const { orderId, biddingId, callback } = payload;
        const response = yield call(deselectBidding, orderId, biddingId);
        if (isEmpty(response)) {
          return;
        }
        const { bidding } = response;
        yield put(actions.deselectBiddingSuccess(bidding));
        callback();
      } catch (e) {
        Logger.log(e);
      }
    },

    *[types.ACCEPT_BIDDING]({ payload }, { call, put }) {
      try {
        const { orderID } = payload;

        const response = yield call(acceptBidding, orderID);
        if (isEmpty(response)) {
          return null;
        }

        const { order } = response;
        yield put(actions.acceptBiddingSuccess(order));

        message.success(formatMessage({ id: 'form.order.accepted-bidding-success' }));
        return order;
      } catch (e) {
        return null;
      }
    },

    *[types.FETCH_INSTANT_PRICE]({ payload }, { call, put }) {
      try {
        const { values } = payload;
        if (isEmpty(values)) {
          yield put(actions.saveShipmentBiddings({ shipmentBidding: { error: true } }));
          return;
        }
        const response = yield call(fetchInstantPrice, values);
        if (response) {
          yield put(actions.saveShipmentBiddings({ shipmentBidding: response }));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    //
    // ──────────────────────────────────────────────────────────────────────── I ──────────
    //   :::::: T R U C K   Q U O T A T I O N S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────────────────
    //

    *[types.FETCH_TRUCK_QUOTATIONS]({ payload }, { call, put }) {
      try {
        const { orderId } = payload;
        const response = yield call(getTruckQuotations, orderId);

        if (response) {
          yield put(actions.saveTruckQuotations(response));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.CHANGE_TRUCK_QUOTATION]({ payload }, { call, put }) {
      try {
        const { orderId, quoId } = payload;
        const response = yield call(changeTruckQuotation, { orderId, quoId });

        if (response) {
          yield put(actions.updateChangedQuotation(response.service_quotation));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.GET_QUOTATION_DETAIL]({ payload }, { call, put }) {
      try {
        const { orderId, quoId, promoId } = payload;
        const response = yield call(getQuotationDetail, { orderId, quoId, promoId });

        if (response) {
          yield put(actions.saveQuotationDetail(response.quotation_details));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.REMOVE_PROMOTION]({ payload }, { call, put }) {
      try {
        const { orderId, quoId } = payload;
        const response = yield call(removePromotion, { orderId, quoId });

        if (response) {
          yield put(actions.updateRemovedPromotion(response.quotation_details));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.UPDATE_ORDER_NOTES_AND_IMAGES]({ payload }, { call, put }) {
      try {
        const { orderId, notes, images } = payload;
        const response = yield call(updateOrderNotesAndImages, { orderId, notes, images });
        if (isEmpty(response)) {
          return;
        }
        yield put(actions.saveOrderDetail({ order: response.order }));
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.GET_PROMOTIONS]({ payload }, { call, put }) {
      try {
        const { code } = payload;
        const response = yield call(getPromotions, code);
        if (response) {
          yield put(actions.savePromotions(response.promotions));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.GET_PAYMENT_METHODS]({ payload }, { call, put }) {
      try {
        const { orderId, fromAreaId, totalPrice } = payload;
        const response = yield call(getPaymentMethods, { orderId, fromAreaId, totalPrice });

        if (response) {
          yield put(actions.savePaymentMethods(response));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.ACCEPT_ORDER]({ payload }, { call, put }) {
      try {
        const { orderId, quoId, params } = payload;

        const response = yield call(acceptOrder, { orderId, quoId, params });
        if (response) {
          yield put(actions.updateOrderDetailAfterAccept(response.order));
        }
      } catch (error) {
        Logger.log(error);
      }
    },

    *[types.GET_BANK_ACCOUNTS](_, { call, put }) {
      try {
        const response = yield call(getBankAccounts);
        if (response) {
          yield put(actions.saveBankAccounts(response));
        }
      } catch (error) {
        Logger.log(error);
      }
    },
  },

  /* ------------------------------------ REDUCERS ------------------------------------ */

  reducers: {
    //
    // ──────────────────────────────────────────────────────────── I ──────────
    //   :::::: O R D E R   L I S T : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────
    //

    [types.SAVE_LIST_ORDERS](state, action) {
      return {
        ...state,
        orders: action.payload.orders,
      };
    },

    //
    // ──────────────────────────────────────────────────────────────── I ──────────
    //   :::::: O R D E R   D E T A I L : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────────
    //

    [types.SAVE_ORDER_DETAIL](state, action) {
      const { order } = action.payload;
      const {
        biddings_truck_count: selectBidAmount,
        selected_biddings_amount: selectBidPrice,
      } = order;

      return {
        ...state,
        orderDetail: order,
        selectBidAmount,
        selectBidPrice,
      };
    },

    [types.UPDATE_ORDER_IN_LIST](state, action) {
      return {
        ...state,
        orders: {
          ...state.orders,
          list: state.orders.list.map(element => {
            if (element.id === action.payload.order.id)
              return { ...element, ...action.payload.order };
            return element;
          }),
        },
      };
    },

    [types.SET_ORDER_TEMPLATES](state, action) {
      return {
        ...state,
        order_templates: action.payload.order_templates,
      };
    },

    [types.SET_ORDER_SUGGESTIONS](state, action) {
      return {
        ...state,
        order_suggestions: action.payload.order_suggestions,
      };
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: S H I P M E N T : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    [types.SAVE_SHIPMENTS](state, { payload }) {
      return {
        ...state,
        shipments: payload.shipments,
      };
    },

    [types.UPDATE_SHIPMENT_IN_LIST](state, { payload }) {
      const { shipments } = state;
      const { updatedShipment } = payload;

      return {
        ...state,
        shipments: shipments.map(shipment =>
          updatedShipment.id === shipment.id ? updatedShipment : shipment
        ),
      };
    },

    [types.SAVE_SHIPMENT_DOCUMENTS](state, { payload }) {
      return {
        ...state,
        shipmentDocs: payload.shipmentDocs,
      };
    },

    [types.SAVE_LOCATIONS](state, action) {
      return {
        ...state,
        locations: action.payload.locations,
      };
    },

    [types.CLEAR_PROPS_IN_SHIPMENTS](state) {
      return {
        ...state,
        shipments: undefined,
        shipmentDocs: undefined,
      };
    },

    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: B I D D I N G S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    [types.FETCH_BIDDINGS_SUCCESS](state, { payload }) {
      const { listBiddings, pageListBiddings } = payload;
      return {
        ...state,
        listBiddings,
        pageListBiddings,
      };
    },

    [types.FETCH_SELECTED_BIDDINGS_SUCCESS](state, { payload }) {
      return {
        ...state,
        listSelectedBiddings: payload.listSelectedBiddings,
      };
    },

    [types.SELECT_BIDDING_SUCCESS](state, { payload }) {
      const {
        bidding,
        bidding: {
          id,
          biddings_truck_count: selectBidAmount,
          selected_biddings_amount: selectBidPrice,
        },
      } = payload;
      const { listBiddings } = state;

      return {
        ...state,
        selectBidPrice,
        selectBidAmount,
        listBiddings: listBiddings.map(item => (item.id === id ? bidding : item)),
      };
    },

    [types.DESELECT_BIDDING_SUCCESS](state, { payload }) {
      const {
        bidding,
        bidding: {
          id,
          biddings_truck_count: selectBidAmount,
          selected_biddings_amount: selectBidPrice,
        },
      } = payload;
      const { listBiddings } = state;

      return {
        ...state,
        selectBidPrice,
        selectBidAmount,
        listBiddings: listBiddings.map(item => (item.id === id ? bidding : item)),
      };
    },

    [types.ACCEPT_BIDDING_SUCCESS](state, { payload }) {
      return {
        ...state,
        listBiddings: state.listSelectedBiddings,
        orderDetail: {
          ...state.orderDetail,
          ...payload.order,
        },
      };
    },

    [types.SAVE_SHIPMENT_BIDDINGS](state, action) {
      return {
        ...state,
        shipmentBidding: action.payload.shipmentBidding,
      };
    },

    [types.RESET_SHIPMENT_BIDDINGS](state) {
      return {
        ...state,
        shipmentBidding: {},
      };
    },

    //
    // ──────────────────────────────────────────────────────────────────────── I ──────────
    //   :::::: T R U C K   Q U O T A T I O N S : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────────────────
    //

    [types.SAVE_TRUCK_QUOTATIONS](state, { payload }) {
      const { quotations } = payload;

      return {
        ...state,
        truckQuotations: quotations.service_quotations,
      };
    },

    [types.UPDATE_CHANGED_QUOTATION](state, { payload }) {
      const { truckQuotations, orderDetail, ...restProps } = state;
      const { quotation } = payload;
      const updatedQuotations = truckQuotations.map(prevQuo => {
        let isChangedInModels = false;
        const models = (prevQuo.models || []).map(prevModel => {
          if (prevModel.id === quotation.id) {
            isChangedInModels = true;
            return {
              ...prevModel,
              selected: true,
            };
          }

          return {
            ...prevModel,
            selected: false,
          };
        });

        if (isChangedInModels) {
          return {
            ...prevQuo,
            models,
            selected: true,
          };
        }

        return {
          ...prevQuo,
          models,
          selected: prevQuo.id === quotation.id,
        };
      });
      return {
        ...restProps,
        truckQuotations: updatedQuotations,
        orderDetail: {
          ...orderDetail,
          selected_service_quotation: quotation,
        },
      };
    },

    [types.SAVE_QUOTATION_DETAIL](state, { payload }) {
      const { quoDetail } = payload;
      return {
        ...state,
        quotationDetail: quoDetail,
      };
    },

    [types.UPDATE_REMOVED_PROMOTION](state, { payload }) {
      const { quoDetail } = payload;
      return {
        ...state,
        quotationDetail: quoDetail,
      };
    },

    [types.SAVE_PROMOTIONS](state, { payload }) {
      const { promotions } = payload;
      return {
        ...state,
        promotions,
      };
    },

    [types.SAVE_PAYMENT_METHODS](state, { payload }) {
      const { paymentMethods } = payload;
      return {
        ...state,
        paymentMethods,
      };
    },

    [types.UPDATE_ORDER_DETAIL_AFTER_ACCEPT](state, { payload }) {
      const { orderDetail } = payload;
      return {
        ...state,
        orderDetail,
      };
    },

    [types.SAVE_BANK_ACCOUNTS](state, { payload }) {
      const { bankAccounts } = payload;
      return {
        ...state,
        bankAccounts,
      };
    },

    [types.CLEAR_PROPS_IN_HYBRID_ORDER](state) {
      return {
        ...state,
        orderDetail: undefined,
        truckQuotations: undefined,
        quotationDetail: undefined,
        promotions: undefined,
        paymentMethods: undefined,
        acceptedOrder: undefined,
        bankAccounts: undefined,
      };
    },
  },
};
